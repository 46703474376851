import { Fragment } from "react";
import type { MODAL_VIEW } from "@src/components/modal-views/context";
import { useModal } from "@src/components/modal-views/context";
import {
  Dialog,
  DialogBackdrop,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import DisconnectWalletModal from "@src/components/modal-views/modals/disconnectWallet";
import RetireDRepModal from "@src/components/modal-views/modals/retireDRep";
import ShareModal from "@src/components/modal-views/modals/share";
import SearchModal from "@src/components/modal-views/modals/searchModal";
import ConnectWalletModal from "@src/components/modal-views/modals/connectWallet";
import FeeInfoDialog from "@src/app/feeInfo/FeeInfoDialog";
import ReviewYourChanges from "@src/components/modal-views/modals/reviewYourChanges";

function renderModalContent(view: MODAL_VIEW | string, modalProps: any) {
  switch (view) {
    case "DISCONNECT_WALLET":
      return <DisconnectWalletModal {...modalProps} />;
    case "RETIRE_DREP":
      return <RetireDRepModal {...modalProps} />;
    case "SHARE":
      return <ShareModal />;
    case "SEARCH":
      return <SearchModal />;
    case "CONNECT_WALLET":
      return <ConnectWalletModal />;
    case "FEE_INFO":
      return <FeeInfoDialog {...modalProps} />;
    case "REVIEW_YOUR_CHANGES":
      return <ReviewYourChanges {...modalProps} />;
    default:
      return <></>;
  }
}

export default function ModalContainer() {
  const { view, isOpen, closeModal, modalProps } = useModal();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={closeModal}
        className="w-screen h-full relative z-[999] transition overflow-y-auto overflow-x-hidden duration-300 ease-out item"
      >
        <DialogBackdrop className="fixed inset-0 bg-overlay-100 dark:bg-dark-neutral-600 dark:bg-opacity-60 dark:backdrop-blur-sm overflow-y-auto overflow-x-hidden flex justify-center items-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-105"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-105"
          >
            <div
              data-testid="modal-view"
              className="relative z-50 min-h-screen flex w-full text-left md:w-fit h-full  items-center justify-center"
            >
              {view && renderModalContent(view, modalProps)}
            </div>
          </TransitionChild>
        </DialogBackdrop>
      </Dialog>
    </Transition>
  );
}
