export default function EmptySearchIllustration(
  props: React.SVGAttributes<{}>,
) {
  return (
    <svg
      width="122"
      height="51"
      viewBox="0 0 122 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2795 45.7996H77.2395C77.5279 45.7996 77.8089 45.7685 78.0796 45.7094C78.3502 45.7685 78.6312 45.7996 78.9195 45.7996H108.04C110.205 45.7996 111.96 44.0446 111.96 41.8796C111.96 39.7147 110.205 37.9596 108.04 37.9596H104.68C102.515 37.9596 100.76 36.2046 100.76 34.0396C100.76 31.8747 102.515 30.1196 104.68 30.1196H115.32C117.485 30.1196 119.24 28.3646 119.24 26.1996C119.24 24.0347 117.485 22.2796 115.32 22.2796H103C105.165 22.2796 106.92 20.5246 106.92 18.3596C106.92 16.1947 105.165 14.4396 103 14.4396H67.1595C69.3245 14.4396 71.0795 12.6846 71.0795 10.5196C71.0795 8.35465 69.3245 6.59961 67.1595 6.59961H35.2395C33.0746 6.59961 31.3195 8.35465 31.3195 10.5196C31.3195 12.6846 33.0746 14.4396 35.2395 14.4396H12.8395C10.6746 14.4396 8.91953 16.1947 8.91953 18.3596C8.91953 20.5246 10.6746 22.2796 12.8395 22.2796H26.8395C29.0045 22.2796 30.7595 24.0347 30.7595 26.1996C30.7595 28.3646 29.0045 30.1196 26.8395 30.1196H4.43953C2.27458 30.1196 0.519531 31.8747 0.519531 34.0396C0.519531 36.2046 2.27458 37.9596 4.43953 37.9596H26.2795C24.1146 37.9596 22.3595 39.7147 22.3595 41.8796C22.3595 44.0446 24.1146 45.7996 26.2795 45.7996ZM117.56 45.7996C119.724 45.7996 121.479 44.0446 121.479 41.8796C121.479 39.7147 119.724 37.9596 117.56 37.9596C115.395 37.9596 113.64 39.7147 113.64 41.8796C113.64 44.0446 115.395 45.7996 117.56 45.7996Z"
        fill="url(#paint0_linear_4310_687)"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.6542 34.0147C66.6542 37.1216 63.8718 40.2002 60.4395 40.2002C57.0073 40.2002 54.2249 37.1216 54.2249 34.0147C54.2249 33.914 54.2278 33.254 54.2336 33.1547H44.0121C43.3005 33.1547 42.8166 32.4324 43.0873 31.7743L47.0899 22.0424C47.2869 21.5151 47.8334 21.1602 48.4484 21.1602H72.4306C73.0456 21.1602 73.5921 21.5151 73.7892 22.0424L78.3595 33.1547H66.6455C66.6512 33.254 66.6542 33.914 66.6542 34.0147Z"
        fill="white"
        fillOpacity="0.18"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5195 33.2062V48.0393C38.5195 48.5806 38.9583 49.0193 39.4995 49.0193H82.0595C82.6008 49.0193 83.0395 48.5806 83.0395 48.0393V33.2062L77.7226 17.4854C77.5879 17.0873 77.2145 16.8193 76.7942 16.8193H44.7648C44.3446 16.8193 43.9711 17.0873 43.8365 17.4854L38.5195 33.2062Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M39.5195 32.9199H40.8782M46 32.9199C48.2045 32.9199 48.2618 32.9199 50.8154 32.9199C51.8661 32.9199 51.8661 33.6583 51.8661 34.0399C51.8661 37.7513 54.944 40.7599 58.7407 40.7599C62.5375 40.7599 65.6153 37.7513 65.6153 34.0399C65.6153 33.6583 65.6153 32.9199 66.666 32.9199H80.6383"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.6953 3.40953L68.4795 10.3833M60.1353 1V10.3833V1ZM45.5195 3.40953L51.7353 10.3833L45.5195 3.40953Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4310_687"
          x1="0.519531"
          y1="26.1996"
          x2="121.48"
          y2="26.1996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
      </defs>
    </svg>
  );
}
