import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { CIP30Instance } from "kuber-client/types";

interface walletInfo {
  stakeAddress: string;
  drepIdBech32: string;
}

export interface IConnectedWallet {
  name: string;
  icon: string;
  instance: CIP30Instance | null;
  network: number | string | null;
  walletInfo: walletInfo | null;
}

const initialState: IConnectedWallet = {
  name: "",
  icon: "",
  instance: null,
  network: null,
  walletInfo: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<IConnectedWallet>) => {
      state = action.payload;
      return state;
    },
    removeWallet: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setWallet, removeWallet } = walletSlice.actions;
export default walletSlice.reducer;
