import LandingPageDrepCard from "../cards/LandingPageDrepCard";
import { demoDReps } from "@src/constants/index";

export default function Dreps() {
  return (
    <div className="flex w-full overflow-x-clip justify-center max-sm:justify-start">
      {demoDReps.map((drep, index) => (
        <LandingPageDrepCard
          key={drep.dRepHandle}
          prop={drep}
          coverPhoto={`/demo-dReps/dRep-cover-${index + 1}.png`}
          profilePhoto={`/demo-dReps/dRep-profile-${index + 1}.png`}
        />
      ))}
    </div>
  );
}
