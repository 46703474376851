import Button from "@src/components/atoms/buttons/button";
import DialogIconWrapper from "@src/components/atoms/dialogIconWrapper";
import EmptyContentIllustration from "@src/components/icons/emptyContentIllustration";
import Wallet from "@src/components/icons/wallet";
import { useModal } from "@src/components/modal-views/context";
import type { CIP30ProviderProxy } from "kuber-client";
import { CIP30Wallet } from "kuber-client";
import {
  useDRepLoginMutation,
  useLazyGetDRepByIdQuery,
} from "@src/store/drep/api";
import { useState } from "react";
import { useParams, useRouter } from "next/navigation";
import { useDispatch } from "react-redux";
import { getDRepIdFromCip30Wallet } from "@src/utils/dRepUtils";
import { bech32 } from "bech32";
import type { DRepMetadata, ILoginRequestBody } from "@src/store/drep/types";
import { setWallet } from "@src/store/user/wallet";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import WalletCard from "@src/components/cards/walletCard";
import WalletDropdown from "@src/components/molecules/walletDropdown";

export default function ConnectWalletModal() {
  const { closeModal } = useModal();
  const [currentSelectedProvider, setCurrentSelectedProvider] =
    useState<CIP30ProviderProxy | null>(null);
  const [connectingProvider, setConnectingProvider] = useState<boolean>(false);
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const [dRepLogin] = useDRepLoginMutation();

  const params = useParams();
  const router = useRouter();
  const dispatch = useDispatch();

  const handleSelect = (provider: CIP30ProviderProxy) => {
    setCurrentSelectedProvider(provider);
  };

  const enableWallet = async () => {
    setConnectingProvider(true);

    if (currentSelectedProvider !== null) {
      setTimeout(async () => {
        try {
          const enabledWallet = await currentSelectedProvider.enable({
            extensions: [
              {
                cip: 95,
              },
            ],
          });

          const drepIdBech32 = await getDRepIdFromCip30Wallet(enabledWallet);
          if (drepIdBech32) {
            const drepIdHex = Buffer.from(
              bech32.fromWords(
                bech32.decode(drepIdBech32 as string, 100).words,
              ),
            ).toString("hex");
            const today = new Date();
            const expirationDate = new Date(today);
            expirationDate.setDate(today.getDate() + 7);
            const isoDateString = expirationDate.toISOString().slice(0, 10);
            const messageUTF8 = `valid until ${isoDateString}`;

            const dRepLoginRequestBody: ILoginRequestBody = {
              signature: await enabledWallet.signData(
                drepIdHex.slice(-56),
                Buffer.from(messageUTF8, "utf-8").toString("hex"),
              ),
              key: (await enabledWallet.cip95?.getPubDRepKey()) as string,
            };

            const stakeAddress =
              (await enabledWallet.instance.getRewardAddresses())[0] || "";

            const response = await dRepLogin(dRepLoginRequestBody);
            if ("data" in response && response.data) {
              const networkId = await enabledWallet.networkId();
              dispatch(
                setWallet({
                  name: currentSelectedProvider.name,
                  icon: currentSelectedProvider.icon,
                  instance: enabledWallet.instance,
                  network: networkId,
                  walletInfo: {
                    stakeAddress,
                    drepIdBech32,
                  },
                }),
              );
            } else {
              throw new Error(`Failed to login`);
            }
            if (!(params && "dRepName" in params)) {
              const dRepInformation: DRepMetadata =
                await getDRepInformationById(drepIdBech32).unwrap();
              if (dRepInformation.token && dRepInformation.token.length !== 0) {
                router.push(`/${dRepInformation.token[0].name}`);
              } else {
                router.refresh();
              }
            }
          }
        } catch (error) {
          toast.error("Error enabling wallet");

          // code 3 for user declining to sign data
          if (error && typeof error == "object") {
            if ("code" in error && error?.code == 3) {
              toast.error(`User declined to sign data`);
            } else {
              toast.error(
                `This wallet does not support signing data using the DRep public key.`,
                {
                  toastId: ToastId.ERROR_TOAST,
                },
              );
            }
          }

          console.error("Error enabling wallet:", error);
        } finally {
          closeModal();
          setConnectingProvider(false);
        }
      }, 1000);
    }
  };
  const nonExperimentalProviders: CIP30ProviderProxy[] = [];
  const experimentalProviders: CIP30ProviderProxy[] = [];
  const unsupportedProviders: CIP30ProviderProxy[] = [];

  CIP30Wallet.listProviders().forEach((provider) => {
    if (
      provider.name.toLowerCase() == "eternl" ||
      provider.name.toLowerCase().includes("yoroi")
    ) {
      nonExperimentalProviders.push(provider);
    } else if (provider.supportedExtensions?.some((ext) => ext.cip === 95)) {
      experimentalProviders.push(provider);
    } else {
      unsupportedProviders.push(provider);
    }
  });

  const allProviders = [
    ...nonExperimentalProviders,
    ...experimentalProviders,
    ...unsupportedProviders,
  ];
  return (
    <div className="p-2 mx-2 border dark:border-dark-neutral-200 rounded-3xl  max-w-[616px] lg:w-[616px] flex text-center ">
      <div className="flex flex-col items-center bg-white gap-5 py-8 px-5 w-full dark:shadow-Drop-Shadow dark:border-dark-neutral-700 relative dark:border dark:bg-dark-neutral-950 dark:bg-opacity-70  shadow-modal-shadow rounded-[18px]">
        <div className="w-full h-[107px] top-0 left-0 absolute bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <DialogIconWrapper>
          <Wallet className="h-6 w-6" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-2">
          <p className="body20 font-semibold dark:text-white">
            Connect your Wallet
          </p>
          <p className="body14 dark:text-gray-400">
            Select your Cardano wallet of choice to connect
          </p>
        </div>
        {allProviders.length == 0 ? (
          <div className="dark:bg-dark-neutral-800 py-8  md:px-[46px] px-8 rounded-2xl w-full flex flex-col items-center justify-center gap-4 ">
            <EmptyContentIllustration />
            <p className="body16 font-medium w-full dark:text-white md:max-w-[318px] max-w-[318px] ">
              No compatible wallet available or user does not have any wallet
              installed on their browser.
            </p>
          </div>
        ) : (
          <div className="flex flex-col gap-5 items-center w-full">
            <div className="flex gap-4 flex-wrap justify-center w-full">
              {nonExperimentalProviders.map((provider, index) => (
                <WalletCard
                  key={provider.name + index}
                  name={provider.name}
                  logo={provider.icon}
                  onClick={() => handleSelect(provider)}
                  onDoubleClick={async () => {
                    handleSelect(provider);
                    await enableWallet();
                  }}
                  disabled={connectingProvider}
                  selected={
                    currentSelectedProvider
                      ? currentSelectedProvider.name === provider.name
                      : false
                  }
                />
              ))}
            </div>
            {(experimentalProviders.length !== 0 ||
              unsupportedProviders.length !== 0) && (
              <div className="flex flex-col dark:bg-dark-neutral-900 border dark:border-dark-neutral-700 rounded-[9px] w-full">
                {experimentalProviders.length !== 0 && (
                  <WalletDropdown
                    title="Experimental Wallets"
                    providers={experimentalProviders}
                    toolTipDescription="These wallets do not support full cip95 features"
                    handleSelect={handleSelect}
                    enableWallet={enableWallet}
                    currentSelectedProvider={currentSelectedProvider}
                    disabled={connectingProvider}
                  />
                )}
                {experimentalProviders.length !== 0 &&
                  unsupportedProviders.length !== 0 && (
                    <div className="h-[1px] w-full bg-dark-neutral-700"></div>
                  )}
                {unsupportedProviders.length !== 0 && (
                  <WalletDropdown
                    title="Unsupported Wallets"
                    providers={unsupportedProviders}
                    toolTipDescription="These wallets do not support any cip95 features"
                    currentSelectedProvider={currentSelectedProvider}
                    disabled
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="bg-layout-divider h-[1px] w-full"></div>
        <div className="flex justify-center gap-3">
          <Button
            onClick={closeModal}
            className=" dark:border-gray-200 dark:bg-white bg-semantic-success-600 text-white dark:text-gray-900 rounded-xl h-[40px] px-3 dark:hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button disabled={connectingProvider} onClick={enableWallet}>
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
}
