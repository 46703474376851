import type { IDetails } from "@src/components/molecules/DRepMetadata";
import { ToastId } from "@src/constants/toastId";
import type {
  IDRepFormField,
  imageObject,
  IMetadata,
  MetadataBody,
  metadataFieldType,
  Reference,
  ReferenceWithId,
} from "@src/models/dtos/metadata";
import { blake2bHex } from "blakejs";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { getImageUrlString } from "./dRepUtils";

export function calculateFileHashFromValue(data: any) {
  try {
    const stringifyData = JSON.stringify(data);
    const buffer = Buffer.from(stringifyData, "utf8");
    return blake2bHex(buffer, undefined, 32);
  } catch (error) {
    console.error("Error converting file", error);
  }
}

export async function getContentFromFile(file: File): Promise<any | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const content = e.target?.result as string;
      try {
        resolve(content); // Resolve the Promise with parsed JSON
      } catch (error) {
        toast.error(
          "Failed to parse the file. Make sure the file format is valid JSON.",
          { toastId: ToastId.ERROR_TOAST },
        );
        reject(error); // Reject the Promise if parsing fails
      }
    };

    reader.onerror = () => {
      toast.error("Failed to read the file.", { toastId: ToastId.ERROR_TOAST });
      reject(new Error("File reading error"));
    };

    reader.readAsText(file);
  });
}

// Validate the structure of the data
export const isValidateMetadata = (data: any): data is IMetadata => {
  try {
    if (typeof data !== "object" || data === null) return false;

    const contextValid = typeof data["@context"] === "object"; // Adjust according to actual context type
    const authorsValid =
      Array.isArray(data.authors) &&
      data.authors.every((a: any) => typeof a === "string");
    const hashAlgorithmValid = typeof data.hashAlgorithm === "string";
    const bodyValid = typeof data.body === "object" && data.body !== null; // Add more validation for MetadataBody

    return contextValid && authorsValid && hashAlgorithmValid && bodyValid;
  } catch (e) {
    console.log(e, "error");
    return false;
  }
};

export const isValidJsonFormat = (value: any) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const generateMetadata = (formData: MetadataBody): IMetadata => {
  const metadata: IMetadata = {
    "@context": {
      "@language": "en-us",
      CIP100:
        "https://github.com/cardano-foundation/CIPs/blob/master/CIP-0100/README.md#",
      CIP119:
        "https://github.com/cardano-foundation/CIPs/blob/master/CIP-0119/README.md#",
      hashAlgorithm: "CIP100:hashAlgorithm",
      body: {
        "@id": "CIP119:body",
        "@context": {
          references: {
            "@id": "CIP119:references",
            "@container": "@set",
            "@context": {
              GovernanceMetadata: "CIP100:GovernanceMetadataReference",
              Identity: "CIP119:IdentityReference",
              Link: "CIP119:LinkReference",
              Other: "CIP100:OtherReference",
              label: "CIP100:reference-label",
              uri: "CIP100:reference-uri",
              referenceHash: {
                "@id": "CIP119:referenceHash",
                "@context": {
                  hashDigest: "CIP119:hashDigest",
                  hashAlgorithm: "CIP100:hashAlgorithm",
                },
              },
            },
          },
          paymentAddress: "CIP119:paymentAddress",
          givenName: "CIP119:givenName",
          image: {
            "@id": "CIP119:image",
            "@context": {
              ImageObject: "https://schema.org/ImageObject",
            },
          },
          schema: "https://schema.org/",
          Person: "schema:Person",
          email: "schema:email",
          nationality: "schema:nationality",
          objectives: "CIP119:objectives",
          motivations: "CIP119:motivations",
          qualifications: "CIP119:qualifications",
          doNotList: "CIP119:doNotList",
        },
      },
      authors: {
        "@id": "CIP100:authors",
        "@container": "@set",
        "@context": {
          name: "http://xmlns.com/foaf/0.1/name",
          witness: {
            "@id": "CIP100:witness",
            "@context": {
              witnessAlgorithm: "CIP100:witnessAlgorithm",
              publicKey: "CIP100:publicKey",
              signature: "CIP100:signature",
            },
          },
        },
      },
    },
    authors: [],
    hashAlgorithm: {
      "@value": "blake2b-256",
    },
    body: {
      doNotList: false,
      givenName: formData.givenName.toString(),
      image: formData.image || "",
      motivations: formData.motivations?.toString() || "",
      objectives: formData.objectives?.toString() || "",
      paymentAddress: formData.paymentAddress?.toString() || "",
      qualifications: formData.qualifications?.toString() || "",
      nationality: formData.nationality?.toString() || "",
      email: formData.email?.toString() || "",
      references: formData.references?.map((reference) => {
        return {
          ...reference,
          label: reference.label.toString(),
          uri: reference.uri.toString(),
        };
      }),
    },
  };
  return metadata;
};

export async function convertFormFieldToDRepMetadataBody(
  dRepFormField: IDRepFormField,
  givenName: string,
  imageUrl?: string,
): Promise<MetadataBody> {
  const {
    objectives,
    motivations,
    paymentAddress,
    qualifications,
    nationality,
    email,
  } = dRepFormField;
  const metadataBody = {
    doNotList: false,
    givenName,
    email,
    nationality,
    objectives,
    motivations,
    paymentAddress,
    qualifications,
    references: filterAndMapReferences(dRepFormField.references),
  };
  if (imageUrl) {
    return { image: await toImageObject(imageUrl), ...metadataBody };
  } else {
    return metadataBody;
  }
}

function filterAndMapReferences(references?: Array<ReferenceWithId>) {
  if (!references) return [];
  return references
    .filter(({ label, uri }) => !isEmpty(label) && !isEmpty(uri))
    .map(({ "@type": type, label, uri }) => ({
      "@type": type,
      label,
      uri,
    }));
}

export function convertDRepMetadataToFormField(
  metadata: IMetadata,
): IDRepFormField {
  const {
    objectives,
    motivations,
    paymentAddress,
    qualifications,
    references,
    image,
    givenName,
    nationality,
    email,
  } = metadata.body;

  return {
    givenName: getFieldValue(givenName),
    image: getImageUrlString(image),
    nationality: getFieldValue(nationality),
    email: getFieldValue(email),
    objectives: getFieldValue(objectives),
    motivations: getFieldValue(motivations),
    qualifications: getFieldValue(qualifications),
    paymentAddress: getFieldValue(paymentAddress),
    references: mapReferencesWithId(references),
  };
}

function mapReferencesWithId(references?: Array<Reference>) {
  if (!references) return [];
  return references.map(({ "@type": type, label, uri }) => ({
    "@type": type,
    label: getFieldValue(label),
    uri: getFieldValue(uri),
    id: uuid(),
  }));
}

export function combineLinkWithHttps(link: string): string {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
}

export function getFieldValue(field: string | metadataFieldType | undefined) {
  return typeof field === "string" ? field : (field?.["@value"] ?? "");
}

export function getValueByPath<T>(obj: T, path: string): any {
  return path.split(".").reduce((acc: unknown, part: string) => {
    if (acc && typeof acc === "object" && part in acc) {
      return (acc as Record<string, unknown>)[part];
    }
    return path;
  }, obj);
}

export const getReferenceDescription = (
  typeFilter: string | string[],
  references?: Reference[],
  defaultLabel: string = "Missing label",
): IDetails[] => {
  if (!references) return [];

  const types = Array.isArray(typeFilter) ? typeFilter : [typeFilter];

  return references
    .filter((reference) => types.includes(reference["@type"]))
    .map((reference) => {
      return {
        label: !isEmpty(getFieldValue(reference.label))
          ? getFieldValue(reference.label)
          : defaultLabel,
        className: isEmpty(getFieldValue(reference.label))
          ? "!text-error-600"
          : "",
        value: getFieldValue(reference.uri),
      };
    });
};

async function toImageObject(imageUrl: string): Promise<imageObject> {
  return {
    "@type": "ImageObject",
    contentUrl: imageUrl,
    sha256: (await calculateImageSHA256(imageUrl)) || "",
  };
}

async function calculateImageSHA256(imageUrl: string) {
  const toHex = (buffer: ArrayBuffer) => {
    return Array.from(new Uint8Array(buffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  };
  try {
    if (imageUrl == "") {
      return "";
    }
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    return toHex(hashBuffer);
  } catch (error) {
    console.error("Error calculating SHA256:", error);
    return null;
  }
}
