import { ILandingPageDRep } from "@src/constants/index";
export default function LandingPageDrepCard({
  prop,
  profilePhoto,
  coverPhoto,
}: {
  prop: ILandingPageDRep;
  profilePhoto: string;
  coverPhoto: string;
}) {
  return (
    <div className="p-2 border-[1px] border-l-[0.5px] border-r-[0.5px] border-[#282B30]">
      <div className="w-[268px]">
        <div className="relative">
          <div
            className="w-full h-[90px] relative grayscale"
            style={{
              backgroundImage: `url(${coverPhoto})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className="absolute bottom-[-30px] left-1/2 translate-x-[-50%]">
            <div
              className={`w-[64.8px] h-[64.8px] rounded-full`}
              style={{
                backgroundImage: `url(${profilePhoto})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </div>
          <div />
        </div>
        <div className="pt-12 pb-6 px-9 text-center">
          <p className="body16 font-semibold">{prop.name}</p>
          <p className="text-gray-400">@{prop.dRepHandle}</p>
          <p className="text-gray-400 label12 font-medium">
            Voting Power : &nbsp;
            <span className="text-white body20 font-bold">
              ₳ {prop.votingPower}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
