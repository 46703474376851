"use client";
import ErrorIcon from "@src/components/icons/error";
import Info from "@src/components/icons/info";
import SuccessIcon from "@src/components/icons/success";
import WarningIcon from "@src/components/icons/warning";
import type { ToastContainerProps, TypeOptions } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { TOASTSTYLES } from "./toastContent";

export const getToastIcon = (type: TypeOptions) => {
  switch (type) {
    case "error":
      return <ErrorIcon className=" text-semantic-error-600 h-6 w-6" />;
    case "warning":
      return <WarningIcon className="text-semantic-warning-500 h-6 w-6" />;
    case "success":
      return <SuccessIcon className=" text-semantic-success-500  h-6 w-6" />;
    case "info":
      return <Info className=" text-semantic-information-500  h-6 w-6" />;
    default:
      return null;
  }
};

export default function CustomizeToastContainer(props: ToastContainerProps) {
  return (
    <ToastContainer
      toastClassName={(context) =>
        `${TOASTSTYLES[context?.type || "default"].parent} ${TOASTSTYLES[context?.type || "default"].description} relative  flex gap-3 p-4 items-start my-2 body14 rounded-xl border-t-[3px]`
      }
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      icon={({ type }) => getToastIcon(type)}
      {...props}
    />
  );
}
