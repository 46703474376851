export default function DarkLogo(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="92"
      height="32"
      viewBox="0 0 92 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.2998 20.5563V7.95H10.85C12.1764 7.95 13.3458 8.21413 14.3581 8.74239C15.3704 9.25865 16.1616 9.98501 16.7317 10.9215C17.3019 11.8579 17.5869 12.9685 17.5869 14.2531C17.5869 15.5258 17.3019 16.6363 16.7317 17.5848C16.1616 18.5212 15.3704 19.2536 14.3581 19.7819C13.3458 20.2981 12.1764 20.5563 10.85 20.5563H5.2998ZM8.12724 18.1611H10.7103C11.5248 18.1611 12.2288 18.005 12.8222 17.6928C13.4272 17.3687 13.8927 16.9125 14.2184 16.3242C14.5559 15.7359 14.7246 15.0455 14.7246 14.2531C14.7246 13.4487 14.5559 12.7584 14.2184 12.1821C13.8927 11.5938 13.4272 11.1436 12.8222 10.8314C12.2288 10.5073 11.5248 10.3452 10.7103 10.3452H8.12724V18.1611Z"
        fill="white"
      />
      <path
        d="M19.7192 20.5563V7.95H25.0076C26.1013 7.95 27.0438 8.13609 27.835 8.50827C28.6262 8.86845 29.2371 9.39071 29.6676 10.0751C30.0981 10.7594 30.3134 11.5758 30.3134 12.5243C30.3134 13.4607 30.0981 14.2711 29.6676 14.9555C29.2371 15.6278 28.6262 16.1441 27.835 16.5042C27.0438 16.8644 26.1013 17.0445 25.0076 17.0445H21.29L22.5467 15.7659V20.5563H19.7192ZM27.486 20.5563L24.4316 15.982H27.4511L30.5403 20.5563H27.486ZM22.5467 16.09L21.29 14.7214H24.8505C25.7232 14.7214 26.3748 14.5293 26.8053 14.1451C27.2358 13.7489 27.4511 13.2086 27.4511 12.5243C27.4511 11.8279 27.2358 11.2877 26.8053 10.9035C26.3748 10.5193 25.7232 10.3272 24.8505 10.3272H21.29L22.5467 8.94049V16.09Z"
        fill="white"
      />
      <path
        d="M37.0216 20.7003C35.9511 20.7003 35.0087 20.4842 34.1942 20.052C33.3913 19.6198 32.7688 19.0315 32.3267 18.2871C31.8845 17.5308 31.6634 16.6723 31.6634 15.7119C31.6634 14.7394 31.8787 13.8809 32.3092 13.1366C32.7514 12.3802 33.3506 11.7919 34.1069 11.3717C34.8632 10.9395 35.7184 10.7234 36.6725 10.7234C37.5918 10.7234 38.4179 10.9275 39.1509 11.3357C39.8956 11.7319 40.4832 12.3082 40.9137 13.0645C41.3442 13.8089 41.5595 14.7034 41.5595 15.7479C41.5595 15.8559 41.5537 15.982 41.542 16.1261C41.5304 16.2581 41.5187 16.3842 41.5071 16.5042H33.88V14.8654H40.0759L39.0287 15.3517C39.0287 14.8474 38.9298 14.4092 38.732 14.037C38.5342 13.6648 38.2608 13.3767 37.9117 13.1726C37.5627 12.9565 37.1554 12.8484 36.69 12.8484C36.2246 12.8484 35.8115 12.9565 35.4508 13.1726C35.1017 13.3767 34.8283 13.6708 34.6305 14.055C34.4327 14.4272 34.3338 14.8714 34.3338 15.3877V15.8199C34.3338 16.3482 34.4443 16.8164 34.6654 17.2246C34.8981 17.6208 35.2181 17.927 35.6253 18.1431C36.0442 18.3472 36.5329 18.4492 37.0914 18.4492C37.5918 18.4492 38.0281 18.3712 38.4004 18.2151C38.7844 18.059 39.1335 17.8249 39.4476 17.5127L40.8962 19.1336C40.4657 19.6378 39.9247 20.028 39.2731 20.3041C38.6215 20.5683 37.871 20.7003 37.0216 20.7003Z"
        fill="white"
      />
      <path
        d="M48.9223 20.7003C48.1311 20.7003 47.4388 20.5202 46.8454 20.1601C46.252 19.7999 45.7865 19.2536 45.4491 18.5213C45.1233 17.7769 44.9604 16.8404 44.9604 15.7119C44.9604 14.5713 45.1175 13.6348 45.4317 12.9025C45.7458 12.1701 46.1996 11.6238 46.793 11.2636C47.3864 10.9035 48.0962 10.7234 48.9223 10.7234C49.8066 10.7234 50.5978 10.9335 51.296 11.3537C52.0057 11.7619 52.5642 12.3382 52.9715 13.0825C53.3904 13.8269 53.5998 14.7034 53.5998 15.7119C53.5998 16.7324 53.3904 17.6148 52.9715 18.3592C52.5642 19.1035 52.0057 19.6798 51.296 20.088C50.5978 20.4962 49.8066 20.7003 48.9223 20.7003ZM43.3722 24.05V10.8674H45.9727V12.8484L45.9203 15.7299L46.0949 18.5933V24.05H43.3722ZM48.4511 18.3952C48.9049 18.3952 49.3063 18.2871 49.6554 18.071C50.0161 17.8549 50.3011 17.5488 50.5106 17.1526C50.7316 16.7444 50.8422 16.2641 50.8422 15.7119C50.8422 15.1476 50.7316 14.6673 50.5106 14.2711C50.3011 13.8749 50.0161 13.5688 49.6554 13.3527C49.3063 13.1366 48.9049 13.0285 48.4511 13.0285C47.9973 13.0285 47.59 13.1366 47.2293 13.3527C46.8686 13.5688 46.5836 13.8749 46.3741 14.2711C46.1647 14.6673 46.06 15.1476 46.06 15.7119C46.06 16.2641 46.1647 16.7444 46.3741 17.1526C46.5836 17.5488 46.8686 17.8549 47.2293 18.071C47.59 18.2871 47.9973 18.3952 48.4511 18.3952Z"
        fill="white"
      />
      <rect
        x="56.9668"
        y="2.13333"
        width="29.7333"
        height="27.7333"
        rx="6.73423"
        fill="url(#paint0_linear_4390_523)"
      />
      <path
        d="M63.4927 12.8382H66.0043V21.5H63.4927V12.8382ZM64.7485 11.6307C64.287 11.6307 63.9113 11.4965 63.6215 11.2282C63.3317 10.9599 63.1868 10.6271 63.1868 10.23C63.1868 9.83286 63.3317 9.50013 63.6215 9.2318C63.9113 8.96346 64.287 8.8293 64.7485 8.8293C65.21 8.8293 65.5857 8.9581 65.8755 9.2157C66.1653 9.4733 66.3102 9.7953 66.3102 10.1817C66.3102 10.6003 66.1653 10.9491 65.8755 11.2282C65.5857 11.4965 65.21 11.6307 64.7485 11.6307ZM68.5124 10.23H73.6322C74.8558 10.23 75.9345 10.4661 76.8683 10.9384C77.8128 11.3999 78.5427 12.0547 79.0579 12.9026C79.5838 13.7505 79.8468 14.738 79.8468 15.865C79.8468 16.992 79.5838 17.9795 79.0579 18.8274C78.5427 19.6753 77.8128 20.3354 76.8683 20.8077C75.9345 21.2692 74.8558 21.5 73.6322 21.5H68.5124V10.23ZM73.5034 19.3587C74.6304 19.3587 75.5266 19.0474 76.1921 18.4249C76.8683 17.7916 77.2064 16.9383 77.2064 15.865C77.2064 14.7917 76.8683 13.9437 76.1921 13.3212C75.5266 12.6879 74.6304 12.3713 73.5034 12.3713H71.1206V19.3587H73.5034Z"
        fill="#FAFAFB"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4390_523"
          x1="71.8335"
          y1="2.13333"
          x2="71.8335"
          y2="29.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#621DD8" />
          <stop offset="1" stopColor="#6106FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
