import cn from "classnames";
import React from "react";
export default function DialogIconWrapper({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cn(
        "h-14 w-14 border p-[6px] dark:border-white dark:border-opacity-5 rounded-xl flex items-center justify-center",
        className,
      )}
    >
      <div className="p-[10px]  bg-Icon-Wrapper-gradient shadow-Icon-Wrapper-shadow rounded-lg">
        {children}
      </div>
    </div>
  );
}
