import React from "react";

export default function LinkIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7806 4.15324C15.1461 3.7877 15.5801 3.49774 16.0577 3.29992C16.5353 3.10209 17.0472 3.00027 17.5641 3.00027C18.081 3.00027 18.5929 3.10209 19.0705 3.29992C19.5481 3.49774 19.9821 3.7877 20.3476 4.15324C20.7131 4.51877 21.0031 4.95272 21.2009 5.43032C21.3987 5.90791 21.5006 6.41979 21.5006 6.93674C21.5006 7.45368 21.3987 7.96556 21.2009 8.44316C21.0031 8.92075 20.7131 9.3547 20.3476 9.72024L16.7206 13.3472C16.327 13.7409 15.8543 14.0467 15.3339 14.2442C14.8134 14.4418 14.2569 14.5267 13.7012 14.4934C13.1455 14.46 12.6032 14.3091 12.1101 14.0507C11.617 13.7923 11.1843 13.4322 10.8406 12.9942C10.7175 12.8378 10.5374 12.7366 10.3397 12.7129C10.142 12.6893 9.94308 12.7452 9.7866 12.8682C9.63012 12.9913 9.52894 13.1715 9.50531 13.3691C9.48168 13.5668 9.53754 13.7658 9.6606 13.9222C10.1354 14.5269 10.733 15.0239 11.414 15.3806C12.095 15.7373 12.8439 15.9455 13.6113 15.9914C14.3787 16.0373 15.1471 15.92 15.8658 15.6471C16.5845 15.3742 17.2371 14.9519 17.7806 14.4082L21.4086 10.7802C21.9134 10.2754 22.3138 9.67615 22.587 9.01659C22.8602 8.35704 23.0008 7.65013 23.0008 6.93624C23.0008 6.22234 22.8602 5.51543 22.587 4.85588C22.3138 4.19632 21.9134 3.59704 21.4086 3.09224C20.9038 2.58744 20.3045 2.18701 19.645 1.91381C18.9854 1.64061 18.2785 1.5 17.5646 1.5C16.8507 1.5 16.1438 1.64061 15.4842 1.91381C14.8247 2.18701 14.2254 2.58744 13.7206 3.09224L10.7206 6.09224C10.5839 6.23362 10.5082 6.42304 10.5098 6.61969C10.5115 6.81634 10.5902 7.00449 10.7292 7.14361C10.8682 7.28273 11.0563 7.3617 11.2529 7.3635C11.4496 7.3653 11.6391 7.28979 11.7806 7.15324L14.7806 4.15324Z"
        fill="currentColor"
      />
      <path
        d="M7.28024 11.6539C7.67384 11.2602 8.14649 10.9544 8.66697 10.7569C9.18745 10.5593 9.74392 10.4744 10.2996 10.5077C10.8554 10.5411 11.3977 10.692 11.8908 10.9504C12.3839 11.2088 12.8166 11.5689 13.1602 12.0069C13.2212 12.0843 13.2968 12.1491 13.3827 12.1973C13.4687 12.2456 13.5633 12.2764 13.6611 12.2881C13.759 12.2998 13.8582 12.2922 13.9531 12.2655C14.048 12.2389 14.1368 12.1938 14.2142 12.1329C14.2917 12.0719 14.3564 11.9963 14.4047 11.9104C14.453 11.8244 14.4838 11.7298 14.4955 11.632C14.5072 11.5341 14.4995 11.4349 14.4729 11.34C14.4462 11.2451 14.4012 11.1563 14.3402 11.0789C13.8655 10.4742 13.2679 9.97716 12.5868 9.62049C11.9058 9.26381 11.1569 9.05564 10.3895 9.00971C9.62215 8.96378 8.85375 9.08114 8.13506 9.35404C7.41637 9.62694 6.76373 10.0492 6.22024 10.5929L2.59224 14.2209C2.08744 14.7257 1.68701 15.3249 1.41381 15.9845C1.14061 16.6441 1 17.351 1 18.0649C1 18.7788 1.14061 19.4857 1.41381 20.1452C1.68701 20.8048 2.08744 21.4041 2.59224 21.9089C3.09704 22.4137 3.69632 22.8141 4.35588 23.0873C5.01543 23.3605 5.72234 23.5011 6.43624 23.5011C7.15013 23.5011 7.85704 23.3605 8.51659 23.0873C9.17615 22.8141 9.77544 22.4137 10.2802 21.9089L13.2802 18.9089C13.3539 18.8402 13.413 18.7574 13.454 18.6654C13.495 18.5734 13.5171 18.4741 13.5188 18.3734C13.5206 18.2727 13.5021 18.1726 13.4644 18.0793C13.4266 17.9859 13.3705 17.901 13.2993 17.8298C13.2281 17.7586 13.1432 17.7025 13.0498 17.6647C12.9564 17.627 12.8564 17.6085 12.7557 17.6103C12.655 17.612 12.5557 17.6341 12.4637 17.6751C12.3717 17.7161 12.2889 17.7752 12.2202 17.8489L9.22024 20.8489C8.85464 21.2144 8.42062 21.5043 7.94297 21.7021C7.46531 21.8999 6.95337 22.0017 6.43638 22.0017C5.39227 22.0016 4.39097 21.5867 3.65274 20.8484C3.2872 20.4828 2.99726 20.0487 2.79946 19.5711C2.60166 19.0934 2.49987 18.5815 2.49992 18.0645C2.50001 17.0204 2.91487 16.0191 3.65324 15.2809L7.28024 11.6539Z"
        fill="currentColor"
      />
    </svg>
  );
}
