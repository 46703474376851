export default function Twitter(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.4165 15.75L8.0778 10.0887M8.0778 10.0887L2.4165 2.25H6.1665L10.2552 7.9113M8.0778 10.0887L12.1665 15.75H15.9165L10.2552 7.9113M15.9165 2.25L10.2552 7.9113"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
