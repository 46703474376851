export default function PlusIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 8.5C14.25 8.69891 14.171 8.88968 14.0303 9.03033C13.8897 9.17098 13.6989 9.25 13.5 9.25H8.75V14C8.75 14.1989 8.67098 14.3897 8.53033 14.5303C8.38968 14.671 8.19891 14.75 8 14.75C7.80109 14.75 7.61032 14.671 7.46967 14.5303C7.32902 14.3897 7.25 14.1989 7.25 14V9.25H2.5C2.30109 9.25 2.11032 9.17098 1.96967 9.03033C1.82902 8.88968 1.75 8.69891 1.75 8.5C1.75 8.30109 1.82902 8.11032 1.96967 7.96967C2.11032 7.82902 2.30109 7.75 2.5 7.75H7.25V3C7.25 2.80109 7.32902 2.61032 7.46967 2.46967C7.61032 2.32902 7.80109 2.25 8 2.25C8.19891 2.25 8.38968 2.32902 8.53033 2.46967C8.67098 2.61032 8.75 2.80109 8.75 3V7.75H13.5C13.6989 7.75 13.8897 7.82902 14.0303 7.96967C14.171 8.11032 14.25 8.30109 14.25 8.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
