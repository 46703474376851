import LoadIcon from "@src/components/icons/loadIcon";
import cn from "classnames";

type ButtonVariant = "primary" | "secondary" | "neutral" | "gray";
export type ButtonSize = "xsmall" | "small" | "medium" | "large" | "xlarge";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  isLoading?: boolean;
  buttonSize?: ButtonSize;
}

const BUTTON_COLORS: Record<ButtonVariant, string> = {
  primary:
    "text-white bg-brand hover:bg-primary-500 disabled:bg-gray-200 disabled:text-gray-400 focus:ring-brand focus:hover:bg-brand",
  secondary:
    "text-primary-800 dark:disabled:text-dark-neutral-100 dark:text-white hover:dark:shadow-Default-Shadow-1 dark:bg-dark-neutral-900 bg-white  hover:border-primary-500 border-[1.5px] hover:bg-primary-50 focus:bg-primary-50 border-brand focus:hover:border-white focus:border-white   disabled:border-gray-200 disabled:dark:border-dark-neutral-700 disabled:text-gray-400 focus:ring-brand focus:dark:ring-offset-brand focus:dark:border-brand dark:disabled:hover:shadow-none",
  neutral:
    "text-gray-900 bg-white disabled:border-transparent disabled:bg-white hover:border-gray-900 hover:bg-gray-50 focus:hover:bg-gray-100 focus:hover:border-transparent focus:bg-gray-100 focus:border-transparent border-gray-200 border-[1.5px] disabled:text-gray-400 focus:ring-gray-950 dark:border-dark-neutral-700 dark:bg-dark-neutral-800 dark:text-white dark:hover:border-white dark:hover:bg-dark-neutral-600 dark:focus:bg-dark-neutral-800 dark:focus:shadow-Dark-Focus-Shadow-1 disabled:dark:bg-dark-neutral-800 disabled:dark:text-dark-neutral-200 dark:disabled:border-dark-neutral-800 ",
  gray: "bg-gray-200 text-gray-900 border border-gray-200 hover:bg-gray-300",
};

const BUTTON_CLASS: Record<ButtonSize, string> = {
  xsmall: "h-9 px-3 body14",
  small: "h-10 px-3 body16",
  medium: "h-11 px-[14px] body16",
  large: "h-12 px-[14px] body16",
  xlarge: "h-[52px] px-[16px] body16",
};

function Button({
  variant = "primary",
  isLoading = false,
  buttonSize = "small",
  children,
  className,
  ...props
}: ButtonProps) {
  return (
    <button
      className={cn(
        "gap-2 rounded-xl flex items-center  font-medium   focus:ring-2 focus:ring-offset-2 ",
        BUTTON_COLORS[variant],
        BUTTON_CLASS[buttonSize],
        className,
      )}
      {...props}
    >
      {children}
      {isLoading && <LoadIcon className=" animate-spin" />}
    </button>
  );
}

export default Button;
