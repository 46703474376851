import React, { useLayoutEffect, useState } from "react";
import Logo from "@src/components/icons/logo";
import DarkLogo from "@src/components/icons/darkLogo";

export default function LogoComponent(props: React.SVGAttributes<{}>) {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const observerRef = React.useRef<MutationObserver | null>(null);

  useLayoutEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new MutationObserver(() => {
        setIsDarkMode(document.documentElement.classList.contains("dark"));
      });

      // Observe changes to the class attribute of the HTML element
      observerRef.current.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ["class"],
      });

      setIsDarkMode(document.documentElement.classList.contains("dark"));

      return () => observerRef.current?.disconnect();
    }
  }, []);

  if (!isDarkMode) return <Logo {...props} />;
  return <DarkLogo {...props} />;
}
