import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import environments from "@src/configs/environments";
import type { TransactionStatusResponse } from "@src/store/transaction/types";

export const TRANSACTION_PATH = "transaction";

export const transactionApi = createApi({
  reducerPath: TRANSACTION_PATH,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    prepareHeaders: (headers) => {
      headers.append("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTransactionStatus: builder.query<TransactionStatusResponse, string>({
      query: (txId) => ({
        url: `/transaction/status/${txId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetTransactionStatusQuery,
  useLazyGetTransactionStatusQuery,
} = transactionApi;
