import { bech32 } from "bech32";
import { blake2bHex } from "blakejs";

export const isEmptyString = (str: string) => {
  return str.length === 0;
};

export const toMidDottedStr = (
  str: string | number,
  leadingVisible = 12,
  firstIndex = 0,
) => {
  if (!str) return str;
  const total = str.toString().length;
  if (total <= leadingVisible * 2) return str;
  const leadingStr = str.toString().substring(firstIndex, leadingVisible);
  const trailingStr = str.toString().substring(total - leadingVisible);
  return `${leadingStr}...${trailingStr}`;
};

export const toEndDottedStr = (
  str: string | number,
  leadingVisible = 12,
  firstIndex = 0,
) => {
  if (!str) return "";
  if (str.toString().length <= leadingVisible) return str.toString();
  const leadingStr = str.toString().substring(firstIndex, leadingVisible);
  return `${leadingStr}...`;
};

export const capitalize = (val: { toString: () => string }): string => {
  if (!val) return "";
  const firstChar = val.toString()[0]?.toUpperCase();
  return `${firstChar}${val.toString().substring(1).toLowerCase()}`;
};

export const ellipsesText = (text: string, limit: number) => {
  let descStripped = text;
  if (text.length > limit) {
    descStripped = toEndDottedStr(text, limit);
  }

  return descStripped.replace(/\\n/gi, "\n");
};

export const trimTooltipTitle = (title?: string, limit: number = 20) => {
  if (title && title.length > limit) return title;
  return "";
};

export const getLastItem = (arr: string) => {
  return arr.charAt(arr.length - 1);
};

export function cn(
  ...classes: (string | undefined | null | boolean)[]
): string {
  return classes.filter(Boolean).join(" ");
}

export function blake2bHash(publicKey: string) {
  //@ts-ignore
  return blake2bHex(Buffer.from(publicKey, "hex"), undefined, 28);
}

// Function to convert a hex string to a Bech32-encoded stake key
export function hexToBech32(hexAddress: string, prefix = "drep") {
  // Convert the hex string into a Buffer (binary data)
  const data = Buffer.from(hexAddress, "hex");

  // Convert binary data into 5-bit groups as needed for Bech32
  const words = bech32.toWords(data);

  // Encode with Bech32 using the given prefix (usually 'stake')
  return bech32.encode(prefix, words);
}

export function middleTruncate(str: string, value: number): string {
  if (str.length <= value) return str; // No truncation needed

  const reserved = 3;
  const usableLength = value - reserved;

  if (usableLength <= 0) return str.slice(0, value);

  const prefixLength = Math.ceil(usableLength / 2);
  const suffixLength = Math.floor(usableLength / 2);

  return `${str.slice(0, prefixLength)}...${str.slice(str.length - suffixLength)}`;
}
