export default function Pointer3d(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="71"
      height="74"
      viewBox="0 0 71 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.48513 5.54636C1.49956 2.99832 4.00586 0.833714 6.78698 1.69698L66.6187 20.4906C68.7287 21.1481 69.5502 23.2573 69.0437 25.0248L66.8382 39.4346C66.5367 41.3115 65.2627 42.8869 63.4959 43.5717L54.3181 47.1326L42.9082 51.5575C42.1406 51.8589 41.5385 52.4344 41.1961 53.174L33.4426 70.2138C31.4838 73.7754 26.3611 73.748 24.4296 70.1727L1.95132 22.1891C1.26648 20.7374 0.951396 19.1346 1.00606 17.5318L1.48513 5.54636Z"
        fill="white"
        fillOpacity="0.03"
        stroke="white"
        strokeOpacity="0.05"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M41.7854 36.9271L34.224 50.6255C32.2652 54.1871 27.1418 54.1597 25.211 50.5844L1.97882 7.51842C0.239388 4.29921 3.29385 0.600915 6.78714 1.69651L66.6196 20.4902C69.9892 21.5448 70.0575 26.2976 66.7153 27.4623L43.4975 35.4891C42.7717 35.7358 42.1552 36.2559 41.7718 36.9415L41.7854 36.9271Z"
        fill="white"
        fillOpacity="0.03"
        stroke="white"
        strokeOpacity="0.05"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M41.6191 52.5011L42.8248 35.8037L41.6191 52.5011Z"
        fill="white"
        fillOpacity="0.03"
      />
      <path
        d="M41.6191 52.5011L42.8248 35.8037"
        stroke="white"
        strokeOpacity="0.05"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M33.4561 70.1989L35.8257 47.625L33.4561 70.1989Z"
        fill="white"
        fillOpacity="0.03"
      />
      <path
        d="M33.4561 70.1989L35.8257 47.625"
        stroke="white"
        strokeOpacity="0.05"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M24.4437 70.1587L23.9092 48.3379L24.4437 70.1587Z"
        fill="white"
        fillOpacity="0.03"
      />
      <path
        d="M24.4437 70.1587L23.9092 48.3379"
        stroke="white"
        strokeOpacity="0.05"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
}
