import CrossIcon from "@src/components/icons/cross";
import withWalletEventListener from "@src/walletEvent/walletStateUtils";
import { useModal } from "@src/components/modal-views/context";

interface FeeInfoDialogPropos {
  feeInfo: (number | bigint)[];
}

function FeeInfoDialog({ feeInfo }: FeeInfoDialogPropos) {
  const parseFeeToAdaString = (): string[] => {
    const feeInAdaString: string[] = [];
    feeInfo.forEach((fee) => {
      const feeBigInt = typeof fee === "bigint" ? fee : BigInt(fee);
      const scaledFee = (feeBigInt * BigInt(100)) / BigInt(1_000_000);
      const roundedFee =
        scaledFee +
        ((feeBigInt * BigInt(100)) % BigInt(1_000_000) > 0
          ? BigInt(1)
          : BigInt(0));
      feeInAdaString.push(`₳ ${(Number(roundedFee) / 100).toFixed(2)}`);
    });
    return feeInAdaString;
  };

  const feeList = parseFeeToAdaString();

  const { closeModal } = useModal();

  return (
    <div className="p-2 mx-2 border dark:border-dark-neutral-200 rounded-3xl md:min-w-[466px] flex ">
      <div className="flex flex-col items-center bg-white gap-5 max-w-[616px]  dark:shadow-Drop-Shadow dark:border-dark-neutral-700 relative dark:border dark:bg-dark-neutral-950 dark:bg-opacity-70  shadow-modal-shadow rounded-[18px] p-8">
        <div className="w-full h-[107px] top-0 left-0 absolute bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <CrossIcon
          onClick={closeModal}
          height={24}
          width={24}
          className="absolute top-4 right-4 cursor-pointer font-bold"
        />
        <h2 className="h24 font-bold text-center">Fee Configuration</h2>
        <p className="body16 text-center">
          The fee structure is designed so that shorter names have a higher fee,
          while longer names incur a lower fee. It eventually stabilizes at{" "}
          <span className="text-primary-200">
            {" "}
            <b>{feeList[feeList.length - 1]}</b>
          </span>{" "}
          for DRep names with a length of {feeList.length} characters or more.
        </p>
        <div className="overflow-hidden rounded-lg border border-gray-300 dark:border-gray-700 w-full">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-left bg-gray-100 dark:bg-gray-800">
                <th className="px-4 py-2 text-gray-700 dark:text-gray-300">
                  Name Length
                </th>
                <th className="px-4 py-2 text-gray-700 dark:text-gray-300">
                  Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {feeList.map((fee, index) => (
                <tr
                  key={index}
                  className="border-t border-gray-200 dark:border-gray-700"
                >
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {index + 1 === feeList.length
                      ? `${index + 1} or more characters`
                      : `${index + 1} character${index + 1 > 1 ? "s" : ""}`}
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {fee}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default withWalletEventListener(FeeInfoDialog);
