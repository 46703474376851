import React from "react";

export default function EditIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0002 16.6665H17.5002M12.5002 4.16646L15.0002 6.66646M13.6468 3.01811C13.9786 2.68637 14.4285 2.5 14.8976 2.5C15.3668 2.5 15.8167 2.68637 16.1485 3.01811C16.4802 3.34985 16.6666 3.79979 16.6666 4.26895C16.6666 4.7381 16.4802 5.18804 16.1485 5.51978L6.14014 15.5289C5.94189 15.7272 5.69683 15.8722 5.42764 15.9506L3.03431 16.6489C2.9626 16.6699 2.88659 16.6711 2.81423 16.6526C2.74188 16.634 2.67583 16.5964 2.62302 16.5436C2.5702 16.4908 2.53255 16.4247 2.51401 16.3524C2.49547 16.28 2.49673 16.204 2.51764 16.1323L3.21598 13.7389C3.29449 13.4701 3.43952 13.2253 3.63764 13.0273L13.6468 3.01811Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
