const environments = {
  // api host configs
  BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL || "/api",
  INTERNAL_API_URL: process.env.INTERNAL_API_URL,
  UMAMI_WEBSITE_ID: process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID,
  METADATA_API_URL: process.env.METADATA_API_URL || "https://metadata.drep.id",
  NETWORK_ID: process.env.NEXT_PUBLIC_NETWORK_ID || "0",
  CARDANO_NETWORK: process.env.NEXT_PUBLIC_CARDANO_NETWORK
    ? process.env.NEXT_PUBLIC_CARDANO_NETWORK.toUpperCase()
    : null,
};
export default environments;
