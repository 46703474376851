import { Tooltip } from "react-tooltip";
import cn from "classnames";

export interface CustomizeToolTipProps {
  title?: string;
  description?: string;
  className?: string;
  id: string;
}
export default function CustomizeToolTip({
  title,
  description,
  className,
  id,
}: CustomizeToolTipProps) {
  return (
    <Tooltip
      place="top"
      opacity={100}
      className={cn(
        "flex flex-col !gap-1 !p-3 max-w-[320px] dark:!bg-dark-neutral-400 !rounded-lg dark:shadow-Tooltip-shadow shadow-l-Shadow-4 !bg-white label12",
        className,
      )}
      id={id}
    >
      {title && (
        <p className="font-semibold text-gray-900  dark:text-white">{title}</p>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          className=" text-gray-600 dark:text-white label12"
        />
      )}
    </Tooltip>
  );
}
