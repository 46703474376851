"use client";

import { features } from "@src/constants";
import { useScrollContext } from "@src/context/scroll-context";
import Seperator from "../icons/Seperator";
import CubeIcon from "../icons/cubeIcon";
import GreenCheck from "../icons/GreenCheck";

export default function Features() {
  const { featuresRef } = useScrollContext();
  return (
    <div
      ref={featuresRef}
      className="flex flex-col gap-y-24 w-full sm:py-[120px] py-[60px] max-md:px-5 max-2xl:px-2 overflow-clip"
    >
      {features.map((feature) => (
        <div
          className="flex flex-col md:flex-row w-full gap-x-5 justify-center"
          key={feature.title}
        >
          <div className="flex flex-col sm:w-[406px]">
            <div>
              <div className="flex gap-2 items-center">
                <CubeIcon color="#6106FF" />
                <p className="uppercase font-bold tiny-text purple-gradient-text">
                  Our Features
                </p>
                <Seperator color="white" />
                <p className="body14-responsive font-medium">
                  {feature.subTitle}
                </p>
              </div>
            </div>
            <p className="font-bold h36-responsive mt-1">{feature.title}</p>
            <p className="font-medium body16 mt-5">{feature.description}</p>
            <div className="flex gap-1 items-center mt-5">
              <GreenCheck />
              <p className="body16 font-medium">{feature.keySentence}</p>
            </div>
          </div>
          <div
            className="w-[720px] h-[616px] max-md:w-[392px] max-md:h-[524px] bg-no-repeat bg-right-top max-md:bg-cover max-md:bg-center max-md:mt-8"
            style={{ backgroundImage: `url(${feature.image})` }}
          />
        </div>
      ))}
    </div>
  );
}
