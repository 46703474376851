import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import cn from "classnames";
import { capitalize } from "@src/utils/stringUtils";

export interface WalletCardProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  name: string;
  logo: string;
  selected?: boolean;
}
export default function WalletCard({
  name,
  logo,
  selected,
  ...props
}: WalletCardProps) {
  return (
    <button
      {...props}
      className={cn(
        selected
          ? "dark:bg-dark-neutral-950 bg-gray-200"
          : "dark:bg-dark-neutral-900 bg-gray-50",
        " hover:dark:bg-dark-neutral-950 hover:bg-gray-200 border flex flex-col items-center gap-3  border-white border-opacity-5 rounded-lg py-4 min-w-[125px]",
      )}
    >
      <img src={logo} className="h-9 w-9" alt={`${name} Icon`} />
      <span className="body16 font-semibold dark:text-white">
        {capitalize(name)}
      </span>
    </button>
  );
}
