import React, { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { isEmptyString } from "@src/utils/stringUtils";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import InputField from "@src/components/atoms/textfield/inputField";
import SearchIcon from "@src/components/icons/search";
import EmptySearchCard from "@src/components/cards/emptySearchCard";
import { useModal } from "@src/components/modal-views/context";

type AutocompleteSearchProps<T> = {
  searchQuery: (term: string) => Promise<T[]>;
  placeholder?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  onItemClick: (item: T) => void;
  debounceDelay?: number;
};

const AutocompleteSearch = <T,>({
  searchQuery,
  placeholder = "Search...",
  renderItem,
  onItemClick,
  debounceDelay = 500,
}: AutocompleteSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<T[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { closeModal } = useModal();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!isEmptyString(value)) {
      try {
        const results = await searchQuery(value);
        setData(results);
        setIsOpen(true);
      } catch (e) {
        toast.error("Failed to fetch search results", {
          toastId: ToastId.ERROR_TOAST,
        });
      }
    } else {
      setData([]);
    }
  };

  const debouncedSearch = useMemo(
    () => debounce(handleInputChange, debounceDelay),
    [debounceDelay],
  );

  return (
    <>
      {isOpen && !isEmptyString(searchTerm) && (
        <div className="fixed inset-0  dark:bg-[#07090C03] backdrop-blur-[3px] z-40"></div>
      )}
      <div
        ref={ref}
        className={cn(
          "relative",
          isOpen &&
            !isEmptyString(searchTerm) &&
            " dark:bg-dark-neutral-900 dark:bg-opacity-80 z-50 bg-white rounded-t-[18px]",
        )}
      >
        <div
          className={cn(
            "p-2",
            isOpen &&
              !isEmptyString(searchTerm) &&
              "dark:border-dark-neutral-200 border border-b-0  rounded-t-[18px]",
          )}
        >
          <InputField
            inputfieldSize="small"
            placeholder={placeholder}
            preActions={[
              <SearchIcon
                key="searchIcon"
                className="text-dark-neutral-900 dark:text-white"
              />,
            ]}
            postActions={[
              <p
                key="shortcut"
                className="dark:text-white body14 font-semibold"
              >
                CTRL+S
              </p>,
            ]}
            variant={
              isEmptyString(searchTerm)
                ? "neutral"
                : data.length != 0
                  ? "neutral"
                  : "error"
            }
            onChange={debouncedSearch}
            className="xl:min-w-[500px]  w-full "
          />
        </div>
        {isOpen && (
          <div
            className={cn(
              "absolute dark:border-dark-neutral-200 gap-2 px-3 py-2 border border-t-0 rounded-b-[18px] dark:bg-dark-neutral-900 dark:bg-opacity-80 bg-white  flex flex-col max-h-[500px] overflow-scroll top-[60px] w-full",
              isEmptyString(searchTerm) && "hidden",
            )}
          >
            {data.length > 0 && (
              <>
                <div className="flex gap-2 items-center dark:text-white">
                  <span className="body14 font-semibold">
                    Best matched DRep IDs
                  </span>
                  <span className="label12">{data.length + 1}+ results</span>
                </div>
                {data.map((item, index) => (
                  <div
                    onClick={() => {
                      closeModal();
                      onItemClick(item);
                      setIsOpen(false);
                    }}
                    className={cn("flex flex-col cursor-pointer")}
                    key={index}
                  >
                    {renderItem(item, index)}
                  </div>
                ))}
              </>
            )}

            {data.length === 0 && !isEmptyString(searchTerm) && (
              <>
                <p className="label12 dark:text-white">0 results</p>
                <EmptySearchCard />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AutocompleteSearch;
