import AvatarIcon from "@src/components/icons/avatarIcon";
import DeleteIcon from "@src/components/icons/delete";
import { getImageUrlString } from "@src/utils/dRepUtils";
import cn from "classnames";

interface AvatarProps {
  src?: string;
  size?: number;
  className?: string;
  handleDeleteImage?: (event: React.MouseEvent) => void;
}

export default function Avatar({
  src,
  size = 24,
  className,
  handleDeleteImage,
}: AvatarProps) {
  return (
    <div
      style={{ width: size, height: size }}
      className={cn(
        "flex items-center relative  dark:bg-white  bg-gray-100   justify-center h-[218px]",
        getImageUrlString(src)
          ? "rounded-full"
          : "p-1 rounded-lg border border-gray-200 dark:border-none",
        className,
      )}
    >
      {getImageUrlString(src) ? (
        <>
          <img
            src={getImageUrlString(src) as string}
            alt="Profile"
            className="w-full h-full rounded-full object-cover"
          />
          {handleDeleteImage && (
            <div
              onClick={handleDeleteImage}
              className="h-10 w-10 absolute dark:bg-white bg-gray-200 bottom-0 -right-4 cursor-pointer flex items-center justify-center rounded-full border-4 dark:border-dark-neutral-900"
            >
              <DeleteIcon className="h-4 w-4 text-semantic-error-700" />
            </div>
          )}
        </>
      ) : (
        <AvatarIcon className=" h-full w-full" />
      )}
    </div>
  );
}
