import type { CIP30Wallet } from "kuber-client";
import { blake2bHash, hexToBech32, isEmptyString } from "./stringUtils";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import environments from "@src/configs/environments";
import type { DRepMetadata, DRepType, TokenType } from "@src/store/drep/types";
import type {
  IDRepDetails,
  IDRepFormField,
  IMetadata,
  MetadataBody,
  ReferenceWithId,
  imageObject,
  imageObjectFieldType,
} from "@src/models/dtos/metadata";
import { isEmpty } from "lodash";
import { getFieldValue } from "./metadataUtils";
import { bech32 } from "bech32";
import type { DRepStatus, tabType } from "@src/models/types/dRep";
import type { BadgeColor } from "@src/components/atoms/badges/badge";

export async function getDRepIdFromCip30Wallet(wallet: CIP30Wallet) {
  const networkId = await wallet.networkId();
  const dRepPubKey = (await wallet.cip95?.getPubDRepKey()) || "";
  const dRepPubKeyHash = blake2bHash(dRepPubKey);
  console.log("DRep PubKey Hash: ", dRepPubKeyHash);

  if (networkId.toString() != environments.NETWORK_ID) {
    console.log(networkId.toString());

    const isTestnet = networkId === 0;
    const toastMessage = `You're connected to the ${isTestnet ? "testnet" : "mainnet"}. Please switch your wallet to the ${isTestnet ? "mainnet" : "testnet"} or choose a different wallet.`;

    toast.error(toastMessage, {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }

  if (dRepPubKey == "") {
    toast.error("Your wallet is not CIP-95 compatible", {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }
  // prefix 22 is for making drepId cip-129 compatible for metadata validation
  const hexDRepId = "22" + dRepPubKeyHash;
  return hexToBech32(hexDRepId);
}

export function lovelaceToAda(lovelace: number) {
  const Ada = lovelace / 1000000;
  return Math.floor(Ada);
}

export function getTokenFeeInAda(
  feeList: (number | bigint)[],
  tokenName: string,
): string {
  const fee =
    tokenName.length >= feeList.length
      ? feeList[feeList.length - 1]
      : feeList[tokenName.length - 1];
  const feeBigInt = typeof fee === "bigint" ? fee : BigInt(fee);
  const scaledFee = (feeBigInt * BigInt(100)) / BigInt(1_000_000);
  const roundedFee =
    scaledFee +
    ((feeBigInt * BigInt(100)) % BigInt(1_000_000) > 0 ? BigInt(1) : BigInt(0));
  return (Number(roundedFee) / 100).toFixed(2);
}

export function formatAda(ada: number): string {
  if (ada >= 1_000_000_000) {
    return `${(ada / 1_000_000_000).toFixed(0)}B`;
  } else if (ada >= 1_000_000) {
    return `${(ada / 1_000_000).toFixed(0)}M`;
  } else if (ada >= 1_000) {
    return `${(ada / 1_000).toFixed(0)}K`;
  } else {
    return ada.toString();
  }
}

export const getMetadataResponse = async ({
  url,
  hash,
  isServersideFetch = true,
}: {
  url: string;
  hash: string;
  isServersideFetch?: boolean;
}) => {
  let metadata: IMetadata | null = null;
  try {
    const metadataUrl = `${environments.METADATA_API_URL}/api/metadata?url=${url}&hash=${hash}`;
    const metadataResponse = await fetch(
      metadataUrl,
      isServersideFetch
        ? {
            headers: {
              "Cache-Control": "invalidate",
            },
            next: { revalidate: 0 },
          }
        : {},
    );

    if (metadataResponse.ok) {
      const metadataJsonResponse = await metadataResponse.json();
      const metadataJsonBody: MetadataBody =
        metadataJsonResponse.metadata["body"];
      if (!isEmpty(metadataJsonBody.givenName as string)) {
        metadata = metadataJsonResponse.metadata;
      }
    } else {
      console.log(url, metadataResponse.status, await metadataResponse.text());
    }
  } catch (error) {
    console.error("Error fetching metadata:", error);
  }
  return metadata;
};

export const getDRepDetails = async ({
  dRepId,
  dRepName,
}: {
  dRepId?: string;
  dRepName?: string;
}): Promise<IDRepDetails> => {
  let dRep: DRepType = {
    drep_details: null,
  };
  let metadata: IMetadata | null = null;
  let token: TokenType | null = null;
  try {
    const url = dRepId
      ? `${environments.INTERNAL_API_URL}/drep?id=${dRepId}`
      : `${environments.INTERNAL_API_URL}/drep?name=${dRepName}`;
    const response = await fetch(url, {
      headers: {
        "Cache-Control": "no-cache",
      },
      next: { revalidate: 0 },
    });

    if (response.ok) {
      const responseJson: DRepMetadata = await response.json();
      dRep = responseJson.dRep;
      token =
        responseJson.token && responseJson.token.length !== 0
          ? responseJson.token[0]
          : null;
      if (dRep && !("drep_details" in dRep) && dRep.url && dRep.dataHash) {
        metadata = await getMetadataResponse({
          url: dRep.url,
          hash: dRep.dataHash,
        });
      }
    } else {
      console.log(url, response.status, await response.text());
    }
    return {
      dRep,
      metadata,
      token,
    };
  } catch (error) {
    console.error("Error fetching dRep details:", error);
    return {
      dRep,
      metadata,
      token,
    };
  }
};

export async function getCanvas(name: string) {
  const url = `${environments.INTERNAL_API_URL}/canvas?text=${name}`;
  const response = await fetch(url, {
    headers: {
      "Cache-Control": "no-cache",
    },
    next: { revalidate: 0 },
  });

  if (response.ok) {
    const imageUrl = await response.json();
    return imageUrl;
  } else {
    console.error(
      "Error fetching canvas image:",
      response.status,
      await response.text(),
    );
    return null;
  }
}

export function getImageUrlString(image: any) {
  let imageUrl;
  const isImageObject = (image: unknown): image is imageObject => {
    return (
      typeof image === "object" &&
      image !== null &&
      (image as imageObject)["@type"] === "ImageObject" &&
      typeof (image as imageObject).contentUrl === "string"
    );
  };
  const isImageObjectFieldType = (
    image: unknown,
  ): image is imageObjectFieldType => {
    return (
      typeof image === "object" &&
      image !== null &&
      typeof (image as imageObjectFieldType)["@value"] === "object" &&
      (image as imageObjectFieldType)["@value"] !== null &&
      (image as imageObjectFieldType)["@value"]["@type"] === "ImageObject" &&
      typeof (image as imageObjectFieldType)["@value"].contentUrl === "string"
    );
  };
  if (isImageObject(image)) {
    imageUrl = image.contentUrl;
  } else if (isImageObjectFieldType(image)) {
    imageUrl = image["@value"].contentUrl;
  } else {
    imageUrl = getFieldValue(image);
  }
  if (imageUrl && !isEmptyString(imageUrl)) {
    return imageUrl;
  }
}

export function bech32ToHex(address: string): string {
  const { words } = bech32.decode(address);
  const buffer = Buffer.from(bech32.fromWords(words)); // Convert from words to bytes
  return buffer.toString("hex"); // Convert to Hex string
}

export function getStatusColor(status: DRepStatus): BadgeColor {
  switch (status) {
    case "Registered":
      return "success";
    case "NotRegistered":
      return "warning";
    case "Retired":
      return "error";
    default:
      return "information";
  }
}

export function findMetadataDifference(
  obj1: IDRepFormField,
  obj2: IDRepFormField,
) {
  const differences: Record<string, any> = {};

  function stripIdFromReference(reference: ReferenceWithId) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = reference;
    return rest;
  }

  for (const key in obj1) {
    if (!(key in obj2)) {
      // @ts-ignore
      differences[key] = obj1[key];
    } else if (key === "references") {
      const refrerences: Record<string, any>[] = [];
      // Handle the 'references' array comparison
      const references1 = obj1[key] as ReferenceWithId[];
      const references2 = obj2[key] as ReferenceWithId[];

      for (const key in references2) {
        if (!(key in references1)) {
          refrerences.push(references2[key]);
        } else if (
          JSON.stringify(stripIdFromReference(references1[key])) !==
          JSON.stringify(stripIdFromReference(references2[key] || {}))
        ) {
          refrerences.push(references2[key]);
        }
      }
      if (refrerences.length != 0) {
        differences[key] = refrerences;
      }
    } else if (
      // @ts-ignore
      JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
    ) {
      //@ts-ignore
      differences[key] = obj2[key];
    }
  }

  for (const key in obj2) {
    if (!(key in obj1)) {
      // @ts-ignore
      differences[key] = obj2[key];
    }
  }

  return differences;
}

export const getChangesValueStatus = (
  type: tabType,
  changesValue: Record<string, any> | null,
): boolean => {
  if (changesValue) {
    const changes = Object.keys(changesValue);
    switch (type) {
      case "Profile":
        return (
          changes.includes("givenName") ||
          changes.includes("email") ||
          changes.includes("nationality")
        );
      case "About yourself":
        return (
          changes.includes("motivations") ||
          changes.includes("objectives") ||
          changes.includes("qualifications")
        );
      case "Payment Address":
        return changes.includes("paymentAddress");
      case "Social Links":
        return (
          changes.includes("references") &&
          changesValue["references"].some(
            (ref: ReferenceWithId) => ref["@type"] !== "Identity",
          )
        );
      case "Idenity":
        return (
          changes.includes("references") &&
          changesValue["references"].some(
            (ref: ReferenceWithId) => ref["@type"] !== "Link",
          )
        );
    }
  }
  return false;
};
