"use client";

import React, { useState, useEffect } from "react";
import AnchorLink from "@src/components/atoms/links/anchor-link";
import Burger from "@src/components/icons/burger";
import { useScrollContext } from "@src/context/scroll-context";
import { usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "@src/store/store";
import { removeWallet } from "@src/store/user/wallet";
import { useDRepLogoutMutation } from "@src/store/drep/api";
import { useLazyGetDRepByIdQuery } from "@src/store/drep/api";
import { getCip30Wallet } from "@src/utils/kuberUtils";
import {
  getDRepIdFromCip30Wallet,
  getImageUrlString,
  getMetadataResponse,
} from "@src/utils/dRepUtils";
import type { DRepMetadata } from "@src/store/drep/types";
import DRepSearch from "@src/components/molecules/dRepSearch";
import withWalletEventListener from "@src/walletEvent/walletStateUtils";
import ProfileDropDown from "@src/components/atoms/dropdown/profileDropdown";
import LogoComponent from "@src/components/atoms/logoComponent";
import type { IMetadata } from "@src/models/dtos/metadata";
import { getFieldValue } from "@src/utils/metadataUtils";
import Button from "@src/components/atoms/buttons/button";
import SearchIcon from "@src/components/icons/search";
import { useModal } from "@src/components/modal-views/context";

function Navbar() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [dRepLogout] = useDRepLogoutMutation();
  const { scrollToSection, howItWorksRef, featuresRef } = useScrollContext();
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const [tokenList, setTokenList] = useState<Array<string>>([]);
  const path = usePathname();
  const [metadata, setMetadata] = useState<IMetadata | null>(null);
  const { openModal } = useModal();

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    scrollToSection(ref);
    if (isMenuVisible) {
      setMenuVisible(false);
    }
  };

  const [isMounted, setIsMounted] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    // This ensures that rendering only happens after the component is mounted on the client.
    // Prevent Hydration error
    setIsMounted(true);
    getTokenList();
  }, [wallet.instance, isMounted]);

  const dispatch = useDispatch();

  const getTokenList = async () => {
    setTokenList([]);
    if (isMounted && wallet.instance !== null) {
      const walletFromKuber = await getCip30Wallet(wallet);
      if (!walletFromKuber) {
        console.error("Error Enabling Wallet");
      } else {
        if (
          (await walletFromKuber.networkId()) != wallet.network ||
          wallet.walletInfo === null
        ) {
          await dRepLogout().unwrap();
          dispatch(removeWallet());
        }
        const dRepId = await getDRepIdFromCip30Wallet(walletFromKuber);
        if (dRepId) {
          try {
            const dRepInformation: DRepMetadata =
              await getDRepInformationById(dRepId).unwrap();
            const dRep = dRepInformation.dRep;
            if (dRepInformation.token && dRepInformation.token.length !== 0) {
              setTokenList(
                dRepInformation.token.map((dRepToken) => dRepToken.name),
              );
            }
            if (
              dRep &&
              !("drep_details" in dRep) &&
              dRep.url &&
              dRep.dataHash
            ) {
              const metadata = await getMetadataResponse({
                url: dRep.url,
                hash: dRep.dataHash,
                isServersideFetch: false,
              });
              setMetadata(metadata);
            }
          } catch (e) {
            console.log("Failed to fetch the dRep information", e);
          }
        }
      }
    }
  };

  const horizontalDivider = () => (
    <div className="bg-layout-divider h-[1px] sm:hidden w-full"></div>
  );

  const getNavBarContent = () => {
    if (isMounted && path === "/") {
      return (
        <>
          <div className="w-[1px] h-5 rounded-[30px] bg-navbar-section-gradent sm:flex hidden "></div>

          <div className="flex items-center md:gap-10 gap-2 sm:flex-row flex-col w-full">
            {horizontalDivider()}
            <p
              className="cursor-pointer sm:p-0 py-5 min-w-[88px]"
              onClick={() => handleNavigation(howItWorksRef)}
            >
              How it works
            </p>
            {horizontalDivider()}
            <p
              className="cursor-pointer sm:p-0 py-5"
              onClick={() => handleNavigation(featuresRef)}
            >
              Features
            </p>
            {/* <ModeChanger className="sm:hidden flex" /> */}
          </div>
        </>
      );
    }
  };

  const getButtonContent = () => {
    if (isMounted) {
      if (wallet.instance) {
        return (
          <ProfileDropDown
            label={wallet.name}
            profileImage={getImageUrlString(metadata?.body?.image)}
            tokenList={tokenList}
            givenName={getFieldValue(metadata?.body?.givenName)}
          />
        );
      }
      return (
        <Button
          className="min-w-[130px]"
          onClick={() => openModal("CONNECT_WALLET")}
        >
          Connect Wallet
        </Button>
      );
    }
    return null;
  };

  return (
    <nav className="relative top-0 !z-30 flex h-[84px] w-full flex-col items-center justify-center bg-white dark:bg-neutral-950 dark:text-white text-neutral-900 ">
      <div className="flex w-full max-w-[1440px] items-center justify-between md:gap-10 gap-2 px-4 sm:px-5 md:px-[72px]">
        <div className="flex items-center md:gap-4 gap-2 body16 font-medium sm:w-fit w-full">
          {isMounted && path === "/" && (
            <div className="flex items-center gap-2 sm:hidden">
              <Burger
                className="h-6 w-6 cursor-pointer dark:text-white text-dark-neutral-800 "
                onClick={() => {
                  setMenuVisible(!isMenuVisible);
                }}
              />
              <div className="w-[1px] h-5 rounded-[30px] bg-navbar-section-gradent "></div>
            </div>
          )}

          <AnchorLink href="/" aria-label="navigate to landing page">
            <LogoComponent className="h-8 w-fit" />
          </AnchorLink>
          <div className="hidden sm:flex items-center md:gap-4 gap-2">
            {getNavBarContent()}
          </div>
        </div>
        <div className=" items-center gap-2 text-text-primary flex w-full justify-end">
          <div className="sm:flex hidden">
            <DRepSearch />
          </div>
          <Button
            className="sm:hidden flex"
            variant="neutral"
            onClick={() => openModal("SEARCH")}
          >
            <SearchIcon className="h-5 w-5" />
          </Button>
          {getButtonContent()}
          {/* <ModeChanger
            className={cn(isMounted && path === "/" && "sm:flex hidden")}
          /> */}
        </div>
      </div>
      {/* <!-- mobile menu --> */}
      {isMenuVisible && (
        <div className="absolute top-[84px] body18 font-medium flex w-full flex-col items-center bg-white dark:bg-dark-neutral-950 gap-8 px-5 min-h-calc-84 sm:hidden">
          {getNavBarContent()}
        </div>
      )}
    </nav>
  );
}

export default withWalletEventListener(Navbar);
