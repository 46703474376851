export default function AvatarIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_ii_2249_7447)">
        <circle cx="12" cy="12" r="12" fill="#B9A6FF" />
        <circle cx="12" cy="12" r="12" fill="url(#paint0_radial_2249_7447)" />
      </g>
      <g filter="url(#filter1_d_2249_7447)">
        <circle cx="12.0003" cy="9.86463" r="4.28571" fill="#EBDFFF" />
      </g>
      <path
        d="M19.7992 21.12V21.1208C17.7021 22.9156 14.9777 24.0001 12.0002 24.0001C10.5447 24.0001 9.14888 23.7408 7.85812 23.2655C6.50766 22.7698 5.27207 22.0375 4.19971 21.1193C4.20575 21.0535 4.21255 20.9885 4.22011 20.9228C4.22691 20.8653 4.23371 20.8079 4.24203 20.7512C4.24429 20.7354 4.24656 20.7203 4.24883 20.7044C4.25639 20.6477 4.26545 20.591 4.27528 20.5351C4.27981 20.5064 4.2851 20.4769 4.29039 20.4474C4.29493 20.4187 4.30097 20.39 4.30626 20.3605C4.31231 20.3295 4.31835 20.2978 4.32516 20.2661C4.3312 20.2343 4.338 20.2033 4.3448 20.1724C4.35161 20.1414 4.35841 20.1104 4.36596 20.0794C4.36899 20.0658 4.37201 20.0522 4.37503 20.0394C4.37806 20.0258 4.38108 20.0121 4.38486 19.9985C4.39468 19.957 4.40451 19.9154 4.41584 19.8739C4.41584 19.8708 4.4166 19.8678 4.41811 19.8655C5.60836 20.8374 6.9883 21.5848 8.49368 22.0435C9.60231 22.3821 10.7805 22.5642 12.0002 22.5642C14.8757 22.5642 17.5154 21.5531 19.5815 19.8663C19.5838 19.8754 19.5861 19.8844 19.5883 19.8943C19.5891 19.8973 19.5898 19.8995 19.5906 19.9026C19.6004 19.9404 19.6095 19.9774 19.6186 20.0152V20.0159C19.6193 20.0189 19.6201 20.0227 19.6208 20.0265C19.6223 20.0318 19.6239 20.0378 19.6246 20.0431C19.6352 20.0862 19.645 20.13 19.6541 20.1731C19.6624 20.2117 19.6707 20.2502 19.6783 20.2895C19.6851 20.3205 19.6911 20.3507 19.6964 20.3817C19.7062 20.4308 19.7145 20.4799 19.7229 20.5298C19.7236 20.5351 19.7251 20.5412 19.7251 20.5472C19.7334 20.5948 19.741 20.6424 19.7478 20.6908C19.7516 20.7135 19.7546 20.7361 19.7576 20.7596C19.7659 20.814 19.7727 20.8684 19.7788 20.9235C19.7863 20.9885 19.7931 21.0543 19.7992 21.12Z"
        fill="#C1A9DD"
      />
      <path
        d="M19.5814 19.8665C17.5153 21.5533 14.8756 22.5644 12.0001 22.5644C9.12381 22.5644 6.48485 21.5533 4.41797 19.8658C4.82832 18.2826 5.72233 16.8936 6.93223 15.8658C7.77561 15.1494 8.98173 15.0897 9.92789 15.6633C10.5672 16.0509 11.267 16.2784 12.0001 16.2784C12.7331 16.2784 13.4321 16.0509 14.0715 15.6633C15.0154 15.0919 16.2192 15.1471 17.0611 15.8605C18.274 16.889 19.1703 18.2795 19.5814 19.8665Z"
        fill="#F4E8FF"
      />
      <defs>
        <filter
          id="filter0_ii_2249_7447"
          x="0"
          y="0"
          width="30"
          height="27.4286"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.42857" />
          <feGaussianBlur stdDeviation="1.71429" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.607835 0 0 0 0 0.365847 0 0 0 0 0.634722 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2249_7447"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" />
          <feGaussianBlur stdDeviation="4.28571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_2249_7447"
            result="effect2_innerShadow_2249_7447"
          />
        </filter>
        <filter
          id="filter1_d_2249_7447"
          x="2.57174"
          y="3.86463"
          width="18.8571"
          height="18.8571"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.42857" />
          <feGaussianBlur stdDeviation="2.57143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.15702 0 0 0 0 0.0625882 0 0 0 0 0.139707 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2249_7447"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2249_7447"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2249_7447"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(10.2838)"
        >
          <stop stopColor="#7A6AB1" stopOpacity="0" />
          <stop offset="1" stopColor="#B9A6FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
