import type { SVGAttributes } from "react";
import React from "react";
import SocialShareWrapper from "@src/components/atoms/socialShareWrapper";
interface SocialIconWrapperProps {
  label: string;
  icon: (props: SVGAttributes<{}>) => React.JSX.Element;
}
export default function SocialIconWrapper({
  label,
  icon,
}: SocialIconWrapperProps) {
  return (
    <div className="flex flex-col items-center gap-2 cursor-pointer">
      <div className="h-12 w-12 rounded-full flex items-center justify-center p-[6px] dark:bg-dark-neutral-500 border dark:border-dark-neutral-700">
        <SocialShareWrapper label={label}>
          {React.createElement(icon, {
            className: "dark:text-white text-dark-neutral-100",
          })}
        </SocialShareWrapper>
      </div>
      <p className="body14 font-medium dark:text-white">{label}</p>
    </div>
  );
}
