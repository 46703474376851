export default function DisconnectWalletIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 20.75V8.75C5 8.19772 5.44772 7.75 6 7.75H22C22.5523 7.75 23 8.19772 23 8.75V20.75C23 21.3023 22.5523 21.75 22 21.75H6C5.44772 21.75 5 21.3023 5 20.75Z"
        fill="url(#paint0_linear_4339_28378)"
      />
      <g filter="url(#filter0_d_4339_28378)">
        <path
          d="M21 7.25V4.25C21 3.98478 20.8946 3.73043 20.7071 3.54289C20.5196 3.35536 20.2652 3.25 20 3.25H7C6.46957 3.25 5.96086 3.46071 5.58579 3.83579C5.21071 4.21086 5 4.71957 5 5.25C5 5.78043 5.21071 6.28914 5.58579 6.66421C5.96086 7.03929 6.46957 7.25 7 7.25H22C22.2652 7.25 22.5196 7.35536 22.7071 7.54289C22.8946 7.73043 23 7.98478 23 8.25V14.0104"
          stroke="#A9A8AB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_4339_28378)">
        <path
          d="M5 5.25V19.25C5 19.7804 5.21071 20.2891 5.58579 20.6642C5.96086 21.0393 6.46957 21.25 7 21.25H14.5"
          stroke="#A9A8AB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter2_d_4339_28378)">
        <path
          d="M19 17.25L24 22.25M24 17.25L19 22.25"
          stroke="#A9A8AB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4339_28378"
          x="0.25"
          y="2.5"
          width="27.5"
          height="20.2605"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_28378"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_28378"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4339_28378"
          x="0.25"
          y="4.5"
          width="19"
          height="25.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_28378"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_28378"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4339_28378"
          x="14"
          y="16.25"
          width="15"
          height="15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_28378"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_28378"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4339_28378"
          x1="14"
          y1="7.75"
          x2="14"
          y2="21.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232125" />
          <stop offset="1" stopColor="#4F4E51" />
        </linearGradient>
      </defs>
    </svg>
  );
}
