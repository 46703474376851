export default function Logo(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="216"
      height="76"
      viewBox="0 0 216 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2473 48.2625V18.6652H26.2781C29.3924 18.6652 32.1379 19.2854 34.5146 20.5256C36.8912 21.7377 38.7489 23.4431 40.0875 25.6417C41.4261 27.8404 42.0954 30.4478 42.0954 33.4639C42.0954 36.4518 41.4261 39.0592 40.0875 41.286C38.7489 43.4847 36.8912 45.2042 34.5146 46.4444C32.1379 47.6565 29.3924 48.2625 26.2781 48.2625H13.2473ZM19.8856 42.639H25.9503C27.8626 42.639 29.5153 42.2726 30.9085 41.5397C32.3291 40.7786 33.4218 39.7075 34.1867 38.3263C34.979 36.9451 35.3751 35.3243 35.3751 33.4639C35.3751 31.5753 34.979 29.9545 34.1867 28.6015C33.4218 27.2203 32.3291 26.1632 30.9085 25.4303C29.5153 24.6693 27.8626 24.2887 25.9503 24.2887H19.8856V42.639Z"
        fill="url(#paint0_linear_2037_888)"
      />
      <path
        d="M47.1016 48.2625V18.6652H59.5178C62.0857 18.6652 64.2985 19.1021 66.1561 19.976C68.0137 20.8216 69.4479 22.0478 70.4587 23.6545C71.4695 25.2612 71.9749 27.178 71.9749 29.4048C71.9749 31.6035 71.4695 33.5062 70.4587 35.1129C69.4479 36.6914 68.0137 37.9035 66.1561 38.7491C64.2985 39.5948 62.0857 40.0176 59.5178 40.0176H50.7896L53.74 37.0156V48.2625H47.1016ZM65.3366 48.2625L58.1655 37.5229H65.2546L72.5076 48.2625H65.3366ZM53.74 37.7766L50.7896 34.5632H59.149C61.1978 34.5632 62.7277 34.1122 63.7384 33.2102C64.7492 32.28 65.2546 31.0115 65.2546 29.4048C65.2546 27.7699 64.7492 26.5015 63.7384 25.5995C62.7277 24.6974 61.1978 24.2464 59.149 24.2464H50.7896L53.74 20.9907V37.7766Z"
        fill="url(#paint1_linear_2037_888)"
      />
      <path
        d="M87.7246 48.6008C85.2113 48.6008 82.9985 48.0934 81.0863 47.0786C79.2013 46.0639 77.7398 44.6827 76.7017 42.935C75.6636 41.1592 75.1445 39.1437 75.1445 36.8887C75.1445 34.6055 75.6499 32.5901 76.6607 30.8424C77.6988 29.0666 79.1057 27.6854 80.8814 26.6988C82.6571 25.684 84.6649 25.1766 86.905 25.1766C89.0632 25.1766 91.0028 25.6558 92.7238 26.6142C94.4722 27.5444 95.8518 28.8974 96.8625 30.6733C97.8733 32.4209 98.3787 34.5209 98.3787 36.9733C98.3787 37.227 98.365 37.5229 98.3377 37.8612C98.3104 38.1713 98.2831 38.4672 98.2558 38.7491H80.3487V34.9015H94.8956L92.437 36.0431C92.437 34.8592 92.2048 33.8303 91.7404 32.9565C91.276 32.0827 90.634 31.4062 89.8144 30.927C88.9949 30.4196 88.0387 30.1659 86.946 30.1659C85.8533 30.1659 84.8835 30.4196 84.0366 30.927C83.2171 31.4062 82.5751 32.0968 82.1107 32.9988C81.6463 33.8726 81.4141 34.9156 81.4141 36.1276V37.1424C81.4141 38.3827 81.6736 39.482 82.1926 40.4404C82.739 41.3706 83.4903 42.0894 84.4464 42.5968C85.4299 43.076 86.5772 43.3156 87.8885 43.3156C89.0632 43.3156 90.0876 43.1323 90.9618 42.7659C91.8633 42.3995 92.6828 41.8498 93.4204 41.1169L96.8216 44.9223C95.8108 46.1062 94.5405 47.0223 93.0107 47.6706C91.4809 48.2907 89.7188 48.6008 87.7246 48.6008Z"
        fill="url(#paint2_linear_2037_888)"
      />
      <path
        d="M115.665 48.6008C113.808 48.6008 112.182 48.178 110.789 47.3323C109.396 46.4867 108.303 45.2042 107.511 43.4847C106.746 41.737 106.364 39.5384 106.364 36.8887C106.364 34.2109 106.732 32.0122 107.47 30.2927C108.207 28.5733 109.273 27.2907 110.666 26.4451C112.059 25.5995 113.726 25.1766 115.665 25.1766C117.742 25.1766 119.599 25.6699 121.238 26.6565C122.905 27.6149 124.216 28.9679 125.172 30.7156C126.156 32.4632 126.647 34.5209 126.647 36.8887C126.647 39.2847 126.156 41.3565 125.172 43.1041C124.216 44.8518 122.905 46.2048 121.238 47.1632C119.599 48.1216 117.742 48.6008 115.665 48.6008ZM102.635 56.4652V25.5149H108.74V30.1659L108.617 36.931L109.027 43.6538V56.4652H102.635ZM114.559 43.1887C115.624 43.1887 116.567 42.935 117.386 42.4276C118.233 41.9203 118.903 41.2015 119.394 40.2713C119.913 39.3129 120.173 38.1854 120.173 36.8887C120.173 35.5639 119.913 34.4364 119.394 33.5062C118.903 32.576 118.233 31.8572 117.386 31.3498C116.567 30.8424 115.624 30.5887 114.559 30.5887C113.494 30.5887 112.537 30.8424 111.691 31.3498C110.844 31.8572 110.174 32.576 109.683 33.5062C109.191 34.4364 108.945 35.5639 108.945 36.8887C108.945 38.1854 109.191 39.3129 109.683 40.2713C110.174 41.2015 110.844 41.9203 111.691 42.4276C112.537 42.935 113.494 43.1887 114.559 43.1887Z"
        fill="url(#paint3_linear_2037_888)"
      />
      <rect
        x="134.553"
        y="5.96521"
        width="68.2"
        height="63.2"
        rx="15.8108"
        fill="url(#paint4_linear_2037_888)"
      />
      <path
        d="M149.874 30.2288H155.771V50.5652H149.874V30.2288ZM152.823 27.3938C151.739 27.3938 150.857 27.0788 150.177 26.4488C149.496 25.8188 149.156 25.0376 149.156 24.1052C149.156 23.1728 149.496 22.3916 150.177 21.7616C150.857 21.1316 151.739 20.8166 152.823 20.8166C153.906 20.8166 154.788 21.119 155.469 21.7238C156.149 22.3286 156.489 23.0846 156.489 23.9918C156.489 24.9746 156.149 25.7936 155.469 26.4488C154.788 27.0788 153.906 27.3938 152.823 27.3938ZM161.66 24.1052H173.68C176.553 24.1052 179.085 24.6596 181.278 25.7684C183.495 26.852 185.209 28.3892 186.419 30.38C187.653 32.3708 188.271 34.6892 188.271 37.3352C188.271 39.9812 187.653 42.2996 186.419 44.2904C185.209 46.2812 183.495 47.831 181.278 48.9398C179.085 50.0234 176.553 50.5652 173.68 50.5652H161.66V24.1052ZM173.378 45.5378C176.024 45.5378 178.128 44.807 179.69 43.3454C181.278 41.8586 182.072 39.8552 182.072 37.3352C182.072 34.8152 181.278 32.8244 179.69 31.3628C178.128 29.876 176.024 29.1326 173.378 29.1326H167.783V45.5378H173.378Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2037_888"
          x1="69.9473"
          y1="18.6652"
          x2="69.9473"
          y2="56.4652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212123" />
          <stop offset="1" stopColor="#404040" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2037_888"
          x1="69.9473"
          y1="18.6652"
          x2="69.9473"
          y2="56.4652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212123" />
          <stop offset="1" stopColor="#404040" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2037_888"
          x1="69.9473"
          y1="18.6652"
          x2="69.9473"
          y2="56.4652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212123" />
          <stop offset="1" stopColor="#404040" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2037_888"
          x1="69.9473"
          y1="18.6652"
          x2="69.9473"
          y2="56.4652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212123" />
          <stop offset="1" stopColor="#404040" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2037_888"
          x1="168.653"
          y1="5.96521"
          x2="168.653"
          y2="69.1652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212123" />
          <stop offset="1" stopColor="#404040" />
        </linearGradient>
      </defs>
    </svg>
  );
}
