"use client";
import type { RootState } from "@src/store/store";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import Button from "../atoms/buttons/button";
import { getStarted } from "@src/constants";
import { useModal } from "../modal-views/context";

export default function GetStarted() {
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const { openModal } = useModal();

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push("/drep/mint");
    } else {
      openModal("CONNECT_WALLET");
    }
  };
  return (
    <div className="flex w-full pt-[72px] lg:pt-24 lg:px-[156px] justify-center max-lg:px-5">
      <div className="p-2 border-[1px] border-dark-neutral-600 rounded-[28px] flex max-lg:w-full">
        <div className="w-full lg:w-[1112px] flex justify-center flex-col items-center gap-y-5 px-16 py-24 rounded-[28px] bg-get-started-background max-lg:px-6 max-lg:py-12">
          <p className="h48 font-semibold max-sm:text-[36px] max-sm:leading-[45px]">
            {getStarted.title}
          </p>
          <p className="font-medium body16 text-center max-w-[500px]">
            {getStarted.description}
          </p>

          <div className="flex items-center gap-x-4">
            <Button onClick={handleButton}>Create your DRep ID</Button>
            <Button variant="secondary">Learn More</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
