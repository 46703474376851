import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { useState } from "react";
import Chevron from "@src/components/icons/chevron";
import Info from "@src/components/icons/info";
import cn from "classnames";
import WalletCardSecondary from "@src/components/cards/walletCardSecondary";
import type { CIP30ProviderProxy } from "kuber-client";
import CustomizeToolTip from "@src/components/atoms/tooltip";

interface WalletDropdownProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  providers: CIP30ProviderProxy[];
  toolTipDescription: string;
  handleSelect?: (provider: CIP30ProviderProxy) => void;
  currentSelectedProvider: CIP30ProviderProxy | null;
  enableWallet?: () => Promise<void>;
}
export default function WalletDropdown({
  title,
  providers,
  toolTipDescription,
  handleSelect,
  currentSelectedProvider,
  enableWallet,
  ...props
}: WalletDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isUnSupported = title.includes("Unsupported");

  const handleToggle = () => setIsOpen(!isOpen);

  const handleProviderClick = (provider: CIP30ProviderProxy) => {
    if (handleSelect) handleSelect(provider);
  };

  const handleProviderDoubleClick = async (provider: CIP30ProviderProxy) => {
    if (handleSelect) handleSelect(provider);
    if (enableWallet) await enableWallet();
  };

  return (
    <div className="flex flex-col gap-4 p-5 w-full">
      <div
        onClick={handleToggle}
        className="flex gap-2 justify-between cursor-pointer items-center"
      >
        <div className="flex gap-3 items-center">
          <div
            className={cn(
              "h-[7px] w-[7px] rounded-full",
              isUnSupported ? " bg-gray-600" : "bg-orange-600",
            )}
          ></div>
          <div className="flex items-center gap-2">
            <p className="body16 font-bold dark:text-white">{title}</p>
            <Info
              data-tooltip-id={title}
              data-tooltip-place="top"
              className="h-4 w-4 text-white"
            />
            <CustomizeToolTip description={toolTipDescription} id={title} />
          </div>
        </div>
        <Chevron
          className={cn(
            "text-neutral-400 cursor-pointer  h-5 w-5 transform transition-transform duration-300 ease-in-out",
            isOpen && "rotate-180",
          )}
        />
      </div>
      {isOpen && (
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full items-center">
          {providers.map((provider, index) => (
            <WalletCardSecondary
              key={provider.name + index}
              name={provider.name}
              logo={provider.icon}
              onClick={() => handleProviderClick(provider)}
              onDoubleClick={() => handleProviderDoubleClick(provider)}
              selected={
                currentSelectedProvider
                  ? currentSelectedProvider.name === provider.name
                  : false
              }
              {...props}
            />
          ))}
        </div>
      )}
    </div>
  );
}
