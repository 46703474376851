import React from "react";
import AutocompleteSearch from "@src/components/atoms/autoCompleteSearch";
import { useLazySearchQuery } from "@src/store/search/api";
import { useRouter } from "next/navigation";
import SearchCard from "@src/components/cards/searchCard";

const DRepSearch = () => {
  const [searchQuery] = useLazySearchQuery();
  const router = useRouter();

  const handleSearchQuery = async (term: string) => {
    const result = await searchQuery(term).unwrap();
    return result;
  };

  const handleItemClick = (item: any) => {
    router.push(`/drep/${item._source.meta.drepIdCip105}`);
  };

  return (
    <AutocompleteSearch
      searchQuery={handleSearchQuery}
      placeholder="Search DReps..."
      renderItem={(item, index) => <SearchCard key={index} item={item} />}
      onItemClick={handleItemClick}
    />
  );
};

export default DRepSearch;
