export default function Seperator(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="4"
      height="6"
      viewBox="0 0 4 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.418542 5.16856C0.235486 5.35163 0.235486 5.64838 0.418542 5.83144C0.601598 6.0145 0.898399 6.0145 1.08145 5.83144L3.58144 3.33146C3.76294 3.15 3.76475 2.85634 3.5855 2.67265L1.14637 0.172652C0.965574 -0.0126479 0.668805 -0.016304 0.483505 0.16449C0.298205 0.345277 0.294549 0.642052 0.475336 0.827352L2.59114 2.99595L0.418542 5.16856Z"
        fill="currentColor"
      />
    </svg>
  );
}
