import React from "react";

export default function MenuIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99967 10.8332C10.4599 10.8332 10.833 10.4601 10.833 9.99984C10.833 9.5396 10.4599 9.1665 9.99967 9.1665C9.53944 9.1665 9.16634 9.5396 9.16634 9.99984C9.16634 10.4601 9.53944 10.8332 9.99967 10.8332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.833 10.8332C16.2932 10.8332 16.6663 10.4601 16.6663 9.99984C16.6663 9.5396 16.2932 9.1665 15.833 9.1665C15.3728 9.1665 14.9997 9.5396 14.9997 9.99984C14.9997 10.4601 15.3728 10.8332 15.833 10.8332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16634 10.8332C4.62658 10.8332 4.99967 10.4601 4.99967 9.99984C4.99967 9.5396 4.62658 9.1665 4.16634 9.1665C3.7061 9.1665 3.33301 9.5396 3.33301 9.99984C3.33301 10.4601 3.7061 10.8332 4.16634 10.8332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
