import React from "react";

export default function Discord(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.626 13.3125L12.596 14.6404C12.6871 14.7845 12.8621 14.8489 13.0218 14.7918C13.6247 14.5765 15.1189 13.9929 16.3492 13.1044C16.4451 13.0351 16.5005 12.9196 16.4998 12.8005C16.4998 6.1875 14.626 4.3125 14.626 4.3125C14.626 4.3125 13.126 3.4516 11.5164 3.19201C11.3581 3.16647 11.203 3.25132 11.1326 3.39668L10.7988 4.08508C10.7988 4.08508 9.96436 3.91048 9.00038 3.91048C8.03641 3.91048 7.20196 4.08508 7.20196 4.08508L6.86822 3.39668C6.79774 3.25132 6.64271 3.16647 6.48435 3.19201C4.87598 3.4514 3.37598 4.3125 3.37598 4.3125C3.37598 4.3125 1.50098 6.1875 1.50098 12.8005C1.50023 12.9196 1.55562 13.0351 1.65158 13.1044C2.88185 13.9929 4.37611 14.5765 4.97893 14.7918C5.13867 14.8489 5.31366 14.7845 5.40475 14.6404L6.37598 13.3125"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.126 12.5625C13.126 12.5625 11.4047 13.6875 9.00098 13.6875C6.59729 13.6875 4.87598 12.5625 4.87598 12.5625"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9385 9.1875C12.9385 10.0159 12.3509 10.6875 11.626 10.6875C10.9011 10.6875 10.3135 10.0159 10.3135 9.1875C10.3135 8.35905 10.9011 7.6875 11.626 7.6875C12.3509 7.6875 12.9385 8.35905 12.9385 9.1875Z"
        stroke="currentColor"
        strokeWidth="1.125"
      />
      <path
        d="M7.68848 9.1875C7.68848 10.0159 7.10085 10.6875 6.37598 10.6875C5.65111 10.6875 5.06348 10.0159 5.06348 9.1875C5.06348 8.35905 5.65111 7.6875 6.37598 7.6875C7.10085 7.6875 7.68848 8.35905 7.68848 9.1875Z"
        stroke="currentColor"
        strokeWidth="1.125"
      />
    </svg>
  );
}
