import React from "react";

export default function Wallet(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 21.0029V9.00293C3 8.45064 3.44772 8.00293 4 8.00293H20C20.5523 8.00293 21 8.45065 21 9.00293V21.0029C21 21.5552 20.5523 22.0029 20 22.0029H4C3.44772 22.0029 3 21.5552 3 21.0029Z"
        fill="url(#paint0_linear_4337_22314)"
      />
      <path
        d="M19 7.50293V4.50293C19 4.23771 18.8946 3.98336 18.7071 3.79582C18.5196 3.60829 18.2652 3.50293 18 3.50293H5C4.46957 3.50293 3.96086 3.71364 3.58579 4.08872C3.21071 4.46379 3 4.9725 3 5.50293C3 6.03336 3.21071 6.54207 3.58579 6.91714C3.96086 7.29222 4.46957 7.50293 5 7.50293H20C20.2652 7.50293 20.5196 7.60829 20.7071 7.79582C20.8946 7.98336 21 8.23771 21 8.50293V12.5029M21 12.5029H18C17.4696 12.5029 16.9609 12.7136 16.5858 13.0887C16.2107 13.4638 16 13.9725 16 14.5029C16 15.0334 16.2107 15.5421 16.5858 15.9171C16.9609 16.2922 17.4696 16.5029 18 16.5029H21C21.2652 16.5029 21.5196 16.3976 21.7071 16.21C21.8946 16.0225 22 15.7681 22 15.5029V13.5029C22 13.2377 21.8946 12.9834 21.7071 12.7958C21.5196 12.6083 21.2652 12.5029 21 12.5029Z"
        stroke="#A9A8AB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.50293V19.5029C3 20.0334 3.21071 20.5421 3.58579 20.9171C3.96086 21.2922 4.46957 21.5029 5 21.5029H20C20.2652 21.5029 20.5196 21.3976 20.7071 21.21C20.8946 21.0225 21 20.7681 21 20.5029V16.5029"
        stroke="#A9A8AB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4337_22314"
          x1="12"
          y1="8.00293"
          x2="12"
          y2="22.0029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F4E51" />
          <stop offset="1" stopColor="#232125" />
        </linearGradient>
      </defs>
    </svg>
  );
}
