export default function WarningIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6667 7.5V9.16667M10.6667 12.5H10.675M4.89321 15.8333H16.4402C17.7232 15.8333 18.5251 14.4444 17.8836 13.3333L12.1101 3.33333C11.4686 2.22222 9.86484 2.22222 9.22334 3.33333L3.44984 13.3333C2.80834 14.4444 3.61021 15.8333 4.89321 15.8333Z"
        stroke="#FC851D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
