export default function WhatsApp(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 16.5C13.6421 16.5 17 13.1421 17 9C17 4.85786 13.6421 1.5 9.5 1.5C5.35786 1.5 2 4.85786 2 9C2 10.0342 2.2093 11.0195 2.58786 11.9158C2.79708 12.4111 2.9017 12.6588 2.91465 12.846C2.9276 13.0332 2.8725 13.2391 2.76231 13.6509L2 16.5L4.84908 15.7377C5.26091 15.6275 5.46683 15.5724 5.65401 15.5854C5.84121 15.5983 6.08889 15.7029 6.58426 15.9121C7.48059 16.2907 8.46582 16.5 9.5 16.5Z"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinejoin="round"
      />
      <path
        d="M6.94111 9.28298L7.59432 8.4717C7.86962 8.12977 8.20993 7.81147 8.23663 7.35619C8.2433 7.2412 8.16245 6.72493 8.0006 5.69239C7.93701 5.28661 7.55815 5.25 7.22999 5.25C6.80236 5.25 6.58854 5.25 6.37621 5.34698C6.10786 5.46956 5.83234 5.81423 5.77188 6.103C5.72404 6.33147 5.75959 6.4889 5.83069 6.80377C6.13267 8.1411 6.84111 9.46185 7.93961 10.5604C9.03815 11.6589 10.3589 12.3674 11.6962 12.6693C12.0111 12.7404 12.1685 12.776 12.397 12.7281C12.6858 12.6677 13.0304 12.3922 13.153 12.1238C13.25 11.9114 13.25 11.6977 13.25 11.27C13.25 10.9418 13.2134 10.563 12.8076 10.4994C11.7751 10.3375 11.2588 10.2567 11.1438 10.2634C10.6885 10.2901 10.3702 10.6304 10.0283 10.9057L9.21702 11.5589"
        stroke="currentColor"
        strokeWidth="1.125"
      />
    </svg>
  );
}
