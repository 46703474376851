import cn from "classnames";
import { capitalize } from "@src/utils/stringUtils";
import type { WalletCardProps } from "@src/components/cards/walletCard";

export default function WalletCardSecondary({
  name,
  logo,
  selected,
  ...props
}: WalletCardProps) {
  return (
    <button
      {...props}
      className={cn(
        selected
          ? "dark:bg-dark-neutral-900 bg-gray-200"
          : "dark:bg-dark-neutral-800 bg-gray-50",
        " hover:dark:bg-dark-neutral-900 dark:text-white disabled:bg-dark-neutral-700 dark:disabled:text-dark-neutral-50 disabled:hover:dark:bg-dark-neutral-800  disabled:hover:bg-gray-50 disabled:cursor-not-allowed hover:bg-gray-200 border flex items-center gap-3  border-white dark:border-dark-neutral-700 border-opacity-5 rounded-lg p-3 min-w-[165px]",
      )}
    >
      <img src={logo} className="h-6 w-6" alt={`${name} Icon`} />
      <span className="body14 font-medium ">{capitalize(name)}</span>
    </button>
  );
}
