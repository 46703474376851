export default function SuccessIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.16669 10L9.83335 11.6667L13.1667 8.33333M18.1667 10C18.1667 14.1421 14.8088 17.5 10.6667 17.5C6.52455 17.5 3.16669 14.1421 3.16669 10C3.16669 5.85786 6.52455 2.5 10.6667 2.5C14.8088 2.5 18.1667 5.85786 18.1667 10Z"
        stroke="#1BC073"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
