import { useModal } from "../context";
import { Button } from "@headlessui/react";
import DisconnectWalletIcon from "@src/components/icons/walletIcon";
import DialogIconWrapper from "@src/components/atoms/dialogIconWrapper";

interface IDisconnectWalletModal {
  handleDisconnectWallet: () => void;
}
export default function DisconnectWalletModal({
  handleDisconnectWallet,
}: IDisconnectWalletModal) {
  const { closeModal } = useModal();
  return (
    <div className="p-2 mx-2 border dark:border-dark-neutral-200 rounded-3xl md:min-w-[466px] flex ">
      <div className="flex flex-col items-center bg-white gap-5 py-8 px-5 w-full dark:shadow-Drop-Shadow dark:border-dark-neutral-700 relative dark:border dark:bg-dark-neutral-950 dark:bg-opacity-70  shadow-modal-shadow rounded-[18px]">
        <div className="w-full h-[107px] top-0 left-0 absolute bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <DialogIconWrapper>
          <DisconnectWalletIcon className="h-6 w-6" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-3 dark:text-white text-center  text-neutral-900">
          <h3 className="h24 font-bold">Disconnect Wallet?</h3>
          <p className="body16 max-w-[326px] w-full">
            Are you sure you want to disconnect the wallet? You can always
            connect again.
          </p>
        </div>
        <div className="h-[1px] w-full bg-section-gradent"></div>
        <div className="flex items-center gap-3 body16 font-medium justify-center">
          <Button
            onClick={closeModal}
            className=" dark:border-gray-200 dark:bg-white bg-semantic-success-600 text-white dark:text-gray-900 rounded-xl h-[40px] px-3 dark:hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDisconnectWallet}
            className="dark:bg-semantic-error-500 bg-semantic-error-600 text-white dark:text-white rounded-xl h-[40px] px-3 dark:hover:bg-semantic-error-700"
          >
            Disconnect
          </Button>
        </div>
      </div>
    </div>
  );
}
