export default function LoadIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.33331 3.33331V7.49998H3.81791M16.6151 9.16665C16.205 5.87805 13.3997 3.33331 9.99998 3.33331C7.20216 3.33331 4.80689 5.05679 3.81791 7.49998M3.81791 7.49998H7.49998M16.6666 16.6666V12.5H16.182M16.182 12.5C15.1931 14.9432 12.7978 16.6666 9.99998 16.6666C6.6003 16.6666 3.79497 14.1219 3.38489 10.8333M16.182 12.5H12.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
