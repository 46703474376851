"use client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkNetworkChange } from "@src/walletEvent/walletEventListener";
import { useDRepLogoutMutation } from "@src/store/drep/api";
import type { RootState } from "@src/store/store";
import { toast } from "react-toastify";

function withWalletEventListener<T extends React.ComponentType<any>>(
  Component: T,
) {
  return (props: React.ComponentProps<T>) => {
    const wallet = useSelector((state: RootState) => state.wallet);
    const [dRepLogout] = useDRepLogoutMutation();
    const dispatch = useDispatch();
    const [isNetworkChanged, setIsNetworkChanged] = useState(false);

    useEffect(() => {
      const checkNetwork = async () => {
        const networkChanged = await checkNetworkChange(
          wallet,
          dRepLogout,
          dispatch,
        );
        if (networkChanged) {
          toast.error("You have been disconnected. Please log in again.");
          window.location.reload();
          setIsNetworkChanged(true);
        }
      };
      checkNetwork();
    }, [wallet, dRepLogout, dispatch]);

    if (isNetworkChanged) {
      return null;
    }

    return <Component {...props} />;
  };
}

export default withWalletEventListener;
