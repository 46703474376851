import cn from "classnames";
import EmptySearchIllustration from "../icons/emptySearchIllustration";

export default function EmptySearchCard({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "flex flex-col gap-4 h-[147px] dark:bg-dark-neutral-800 rounded-lg text-center px-4 justify-center items-center",
        className,
      )}
    >
      <EmptySearchIllustration />
      <p className="label12 dark:text-white">
        No DRep found matching your search. Please try another name or DRep ID.
      </p>
    </div>
  );
}
