import Image from "next/image";
import Spinner from "@src/assets/images/spinner.gif";

export default function TransactionInProgressBadge() {
  return (
    <div className="flex items-center gap-1 bg-semantic-warning-600 h-9 px-3 rounded-xl">
      <Image
        src={Spinner}
        alt="spinner"
        height={14}
        width={14}
        className="object-cover"
      />
      <p className="body14 font-semibold text-white">Transaction in progress</p>
    </div>
  );
}
