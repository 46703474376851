export default function GreenCheck(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5 12C21.5 13.268 20.87 14.39 19.907 15.068C20.0108 15.6608 19.9701 16.2698 19.7886 16.8436C19.607 17.4173 19.2899 17.9388 18.864 18.364C18.4388 18.7899 17.9173 19.107 17.3436 19.2886C16.7698 19.4701 16.1608 19.5108 15.568 19.407C15.2222 19.8995 14.7629 20.3014 14.2288 20.5787C13.6948 20.856 13.1017 21.0005 12.5 21C11.232 21 10.11 20.37 9.432 19.407C8.83923 19.5107 8.23021 19.47 7.65649 19.2885C7.08276 19.1069 6.56122 18.7898 6.136 18.364C5.71013 17.9388 5.39298 17.4173 5.21142 16.8436C5.02987 16.2698 4.98925 15.6608 5.093 15.068C4.60052 14.7222 4.19862 14.2629 3.92133 13.7288C3.64403 13.1948 3.49951 12.6017 3.5 12C3.5 10.732 4.13 9.61 5.093 8.932C4.98925 8.33923 5.02987 7.73019 5.21142 7.15645C5.39298 6.58271 5.71013 6.06117 6.136 5.636C6.56122 5.21019 7.08276 4.8931 7.65649 4.71154C8.23021 4.52999 8.83923 4.48933 9.432 4.593C9.77785 4.10058 10.2372 3.69873 10.7713 3.42144C11.3053 3.14415 11.8983 2.99959 12.5 3C13.768 3 14.89 3.63 15.568 4.593C16.1608 4.48933 16.7698 4.52999 17.3435 4.71154C17.9172 4.8931 18.4388 5.21019 18.864 5.636C19.2898 6.06122 19.6069 6.58276 19.7885 7.15649C19.97 7.73021 20.0107 8.33923 19.907 8.932C20.3995 9.27778 20.8014 9.73715 21.0787 10.2712C21.356 10.8052 21.5005 11.3983 21.5 12Z"
        fill="#11A862"
      />
      <path
        d="M9.5 12.75L11.75 15L15.5 9.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
