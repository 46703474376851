export default function LinkedIn(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.60001 19H2.2V8.3H5.60001V19ZM3.9 6.8C2.8 6.8 2 6 2 4.9C2 3.8 2.9 3 3.9 3C5 3 5.8 3.8 5.8 4.9C5.8 6 5 6.8 3.9 6.8ZM18 19H14.6V13.2C14.6 11.5 13.9 11 12.9 11C11.9 11 10.9 11.8 10.9 13.3V19H7.5V8.3H10.7V9.8C11 9.1 12.2 8 13.9 8C15.8 8 17.8 9.1 17.8 12.4V19H18Z"
        fill="currentColor"
      />
    </svg>
  );
}
