import CrossIcon from "@src/components/icons/cross";
import { useModal } from "../context";
import LinkIcon from "@src/components/icons/link";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import DialogIconWrapper from "@src/components/atoms/dialogIconWrapper";
import ShareIcon from "@src/components/icons/share";
import CopyIcon from "@src/components/icons/copy";
import SocialIconWrapper from "@src/components/atoms/socialIconWrapper";
import { socialShareIcons } from "@src/constants";

export default function ShareModal() {
  const { closeModal } = useModal();

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Copied to clipboard", { toastId: ToastId.SUCCESS_TOAST });
  };

  const horizontalDivider = () => (
    <div className="bg-layout-divider h-[1px] w-full"></div>
  );

  return (
    <div className="p-2 border dark:border-dark-neutral-200 rounded-3xl max-w-[466px] w-full mx-5 flex text-center ">
      <div className="flex  flex-col items-center bg-white gap-5 py-8 px-5 w-full dark:shadow-Drop-Shadow dark:border-dark-neutral-700 relative dark:border dark:bg-dark-neutral-950 dark:bg-opacity-70  shadow-modal-shadow rounded-[18px]">
        <div className="w-full h-[107px] top-0 left-0 absolute bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <CrossIcon
          onClick={closeModal}
          className="absolute top-4 cursor-pointer right-4 h-[18px] w-[18px] dark:text-white"
        />
        <DialogIconWrapper>
          <ShareIcon className="h-6 w-6 text-[#A9A8AB]" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-2 dark:text-white">
          <p className="h24 font-bold"> Share this profile?</p>
          <p className="body16 max-w-[300px] w-full">
            Share it to your teammates or you can share the direct link to
            others.
          </p>
        </div>
        {horizontalDivider()}
        <div className="grid gap-6 grid-cols-4">
          {socialShareIcons.map((icon, index) => (
            <SocialIconWrapper
              key={index}
              icon={icon.icon}
              label={icon.label}
            />
          ))}
        </div>
        {horizontalDivider()}
        <div className="h-[46px] p-2 w-full border dark:border-dark-neutral-700 dark:bg-dark-neutral-600 rounded-xl flex justify-between gap-2 items-center">
          <div className="flex items-center gap-2">
            <LinkIcon className="h-[18px] w-[18px] dark:text-white text-dark-neutral-900" />
            <div className="w-[1px] bg-black bg-opacity-20 dark:bg-white rounded-[30px] h-[14px]"></div>
            <p className="body14 sm:max-w-[290px] max-w-[180px] truncate font-medium dark:text-white text-dark-neutral-700">
              {window.location.href}
            </p>
          </div>
          <div className="dark:bg-dark-neutral-400 p-[6px] bg-gray-100 hover:bg-gray-200 dark:hover:bg-dark-neutral-500 rounded-[6px]">
            <CopyIcon
              onClick={handleCopy}
              className="h-[18px] w-[18px] cursor-pointer dark:text-white text-dark-neutral-700"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
