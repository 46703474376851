import React from "react";

export default function CopyIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4467_1549)">
        <path
          d="M3.33317 13.3337C2.4165 13.3337 1.6665 12.5837 1.6665 11.667V3.33366C1.6665 2.41699 2.4165 1.66699 3.33317 1.66699H11.6665C12.5832 1.66699 13.3332 2.41699 13.3332 3.33366M8.33317 6.66699H16.6665C17.587 6.66699 18.3332 7.41318 18.3332 8.33366V16.667C18.3332 17.5875 17.587 18.3337 16.6665 18.3337H8.33317C7.4127 18.3337 6.6665 17.5875 6.6665 16.667V8.33366C6.6665 7.41318 7.4127 6.66699 8.33317 6.66699Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4467_1549">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
