"use client";
import Button from "@src/components/atoms/buttons/button";
import { banner } from "@src/constants";
import type { RootState } from "@src/store/store";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import SparkIcon from "@src/components/icons/sparkIcon";
import GreenCheck from "@src/components/icons/GreenCheck";
import { useModal } from "../modal-views/context";

export default function Banner() {
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const { openModal } = useModal();

  const onSignIn = () => {
    openModal("CONNECT_WALLET");
  };

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push("/drep/mint");
    } else {
      onSignIn();
    }
  };

  return (
    <div className="flex justify-center items-center flex-col w-full bg-banner-background-pattern bg-opacity-50 relative overflow-clip  max-md:px-1">
      <div className="max-w-[713px] flex justify-center items-center flex-col my-24 sm:my-32 w-full z-20 gap-2 sm:gap-5">
        <div className="flex gap-[3px] bg-dark-neutral-700 border-dark-neutral-100 border-[1px] rounded-[32px] px-3 py-1 justify-center items-center shadow-introducing-shadow backdrop-blur-[6px]">
          <SparkIcon className="text-[#FFFFFF80]" />
          <span className="body14 font-medium">Introducting to DRepID</span>
        </div>
        <div className="flex flex-col gap-y-5 w-full items-center justify-center relative">
          <div className="relative bg-banner-text-box-pruple-gradient">
            <div className="text-center">
              <div className="absolute flex w-full justify-center  h-[300px] bg-banner-text-box-purple-gradient items-center opacity-100">
                <div className="bg-banner-text-box-purple-line w-[448px] h-[1px]" />
              </div>
              <span className="h56-responsive font-bold  sm:!leading-[70px]">
                {banner.title}
              </span>
            </div>
          </div>
          <p className="body1 text-center">{banner.description}</p>
          <div className="flex gap-x-5 max-md:gap-x-3">
            {banner.features.map((feature) => {
              return (
                <div key={feature} className="flex gap-x-1 items-center">
                  <GreenCheck />
                  <p className="body16-responsive font-medium">{feature}</p>
                </div>
              );
            })}
          </div>
          <Button
            onClick={handleButton}
            buttonSize="large"
            className="shadow-Inner-B-Shadow-6 inner-shadow-white shadow-white inset"
          >
            Create your DRep ID
          </Button>
        </div>
      </div>
      <div className="bg-banner-ellipse h-[1052px] min-w-[1440px] w-full absolute z-10 bottom-0" />
      <div className="h-full w-full absolute top-0 from-black via-black to-transparent bg-gradient-to-b z-[5]" />
      <div className="bg-profile-illustration-left bg-no-repeat bg-contain h-full w-full z-[5] absolute shadow-Profile-Illustration-left-shadow max-md:[background-position:10%_80%] max-md:opacity-50" />
      <div className="bg-profile-illustration-right bg-no-repeat bg-contain h-full w-full z-[5] absolute bg-right-bottom shadow-Profile-Illustration-right-shadow max-md:w-1/2 right-0 max-md:opacity-50" />
    </div>
  );
}
