"use client";
import { useScrollContext } from "@src/context/scroll-context";
import CubeIcon from "../icons/cubeIcon";
import DialogIconWrapper from "../atoms/dialogIconWrapper";
import Wallet from "../icons/wallet";
import Image from "next/image";
import ShareIcon from "../icons/share";
import MenuIcon from "../icons/menu";
import CopyIcon from "../icons/copy";
import EditIcon from "../icons/edit";
import GreenCheck from "../icons/GreenCheck";
import LogoComponent from "../atoms/logoComponent";
import { howItWorks } from "@src/constants";

export default function HowItWorks() {
  const { howItWorksRef } = useScrollContext();
  return (
    <div ref={howItWorksRef} className="w-full p-3">
      <div className="w-full border-[1px] flex flex-col border-[#FFFFFF14] rounded-6xl items-center py-[96px] relative">
        <div className="w-full h-[220px] bg-section-divider absolute top-0 translate-y-[-50%]" />
        <div className="w-full h-[422px] bg-how-it-works-bg bg-center absolute bottom-0 z-10 bg-no-repeat bg-cover" />
        <div className="flex flex-col w-[500px] max-xl:w-full items-center gap-y-2 mb-[80px] z-20 max-xl:px-5">
          <div className="flex gap-2 items-center">
            <CubeIcon fill="#6106FF" />
            <p className="tiny-text font-bold purple-gradient-text">
              How it Works
            </p>
          </div>
          <p className="h40-responsive font-bold text-center">
            {howItWorks.title}
          </p>
          <p className="body16 font-medium text-gray-400 text-center">
            {howItWorks.description}
          </p>
        </div>
        <div className="flex flex-col gap-6 w-[1128px] max-xl:w-full z-20 max-xl:px-3 ">
          <ConnectWalletSteps />
          <div className="flex max-xl:flex-col w-full gap-6">
            <div className="w-full bg-[#111316] rounded-4xl relative overflow-clip ">
              <div className="p-8 pb-0 max-xl:px-4">
                <p className="body20 font-semibold max-xl:text-center">
                  {howItWorks.steps[1].title}
                </p>
                <p className="body16 text-gray-400 font-medium max-xl:text-center">
                  {howItWorks.steps[1].description}
                </p>
              </div>
              <div className="bg-[url('/how-it-works-1.png')] w-full h-[445px] max-xl:h-[294px] mt-6 overflow-clip bg-no-repeat bg-right-bottom rel max-sm:bg-left-top max-xl:bg-center" />
              <div className="h-1/3 w-full absolute bottom-0 from-transparent via-black to-black  bg-gradient-to-b opacity-50" />
            </div>
            <div className="w-full bg-[#111316] rounded-4xl relative overflow-clip">
              <div className="p-8 pb-0 max-xl:px-4">
                <p className="body20 font-semibold max-xl:text-center">
                  {howItWorks.steps[2].title}
                </p>
                <p className="body16 text-gray-400 font-mediumm max-xl:text-center">
                  {howItWorks.steps[2].description}
                </p>
              </div>
              <div className="max-xl:w-full flex justify-center">
                <div className="bg-[url('/how-it-works-2.png')] w-full h-[445px] mt-6 overflow-clip bg-no-repeat bg-center max-xl:h-[318px] max-xl:w-[282px] max-xl:bg-cover" />
              </div>
              <div className="h-1/3 w-full absolute bottom-0 from-transparent via-black to-black  bg-gradient-to-b opacity-50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ConnectWalletDialog = () => {
  return (
    <div className="w-[400px] max-xl:w-[303px] text-center px-8 max-xl:px-4 py-12 rounded-2xl bg-dark-neutral-700 gap-5 flex flex-col items-center">
      <DialogIconWrapper>
        <Wallet />
      </DialogIconWrapper>
      <div className="text-center gap-1">
        <p className="font-semibold body20-responsive">Connect your Wallet</p>
        <p className="text-gray-400 body14 max-xl:text-[10.63px] max-xl:leading-[15.19px] max-xl:tracking-[-0.07px]">
          Select the kind of wallet you want to connect.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        {["Lace", "Eternl"].map((wallet) => (
          <div
            key={wallet}
            className="h-[130px] max-xl:h-[98px] w-full bg-dark-neutral-900 rounded-lg flex flex-col justify-center items-center"
          >
            <Image
              src={`/features/${wallet.toLowerCase()}.png`}
              alt={wallet}
              width={wallet === "Lace" ? 47 : 41}
              height={wallet === "Lace" ? 44 : 38}
              className={
                wallet === "Lace"
                  ? "max-xl:w-[35.7px] max-xl:h-[33.42px]"
                  : "max-xl:w-[31.4px] max-xl:h-[28.87px]"
              }
            />
            <p className="body16 font-semibold max-xl:text-[12.15px] max-xl:leading-[18.23px] max-xl:tracking-[-0.14px]">
              {wallet}
            </p>
          </div>
        ))}
      </div>
      <div></div>
      <div className="flex justify-center items-center gap-x-2">
        <p className="body14 font-semibold py-2 px-4 bg-white text-black rounded-xl">
          Cancel
        </p>
        <p className="body14 font-semibold py-2 px-4 bg-primary-800 rounded-xl">
          Connect
        </p>
      </div>
    </div>
  );
};

const DRepProfile = () => {
  return (
    <div className="w-[288px] rounded-xl border-[1px] border-dark-neutral-700 overflow-clip">
      <div className="w-full relative ovef">
        <div className="bg-[url('/demo-dReps/dRep-cover-1.png')] w-full h-[100px] bg-cover bg-no-repeat" />
        <div className="bg-[url('/demo-dReps/dRep-profile-high-reso.jpeg')] w-[86.4px] h-[86.4px] bg-cover bg-center rounded-full absolute left-1/2 -translate-x-1/2 -translate-y-3/4" />
      </div>
      <div className="py-8 flex flex-col justify-center gap-y-4">
        <div className="text-center">
          <p className="body18 font-semibold">Eleanor Pena</p>
          <p className="text-gray-400 label12">@eleanor-drep1</p>
          <p className="label12 text-gray-400">
            Voting Power : &nbsp;{" "}
            <span className="h24 font-bold text-white"> ₳ 1836.98</span>
          </p>
        </div>
        <div className="flex gap-1 items-center justify-center">
          <p className="body14 font-medium text-black bg-white py-2 px-3 rounded-xl w-fit">
            Delegate your Vote
          </p>
          <div className="px-[10px] py-2 bg-dark-neutral-700 rounded-xl">
            <ShareIcon stroke="white" width={21} height={21} />
          </div>
          <div className="px-[10px] py-2 bg-dark-neutral-700 rounded-xl">
            <MenuIcon stroke="white" width={21} height={21} />
          </div>
        </div>
        <div></div>
        <div className="flex justify-center gap-2">
          <div className="flex gap-1 items-center bg-dark-neutral-700 py-[6px] px-[10px] rounded-xl">
            <p className="label12 font-medium">drep1glrdxa4t66m9</p>
            <CopyIcon width={12} height={12} />
          </div>
          <div className="flex gap-1 items-center bg-dark-neutral-700 py-[6px] px-[10px] rounded-xl">
            <p className="label12 font-medium">Edit Profile</p>
            <EditIcon width={12} height={12} />
          </div>
        </div>
      </div>
      <div className="px-[16px] py-[10px] border-t-[1px] border-dark-neutral-700 flex justify-between bg-[#181A1D]">
        <div className="flex gap-1 items-center font-semibold label12">
          <GreenCheck />
          <p>Registered</p>
        </div>
        <LogoComponent />
      </div>
    </div>
  );
};

const ConnectWalletSteps = () => {
  return (
    <div className="bg-dark-neutral-900 px-8 py-12 rounded-4xl gap-y-12 flex flex-col justify-center items-center w-full max-xl:px-5">
      <div className="w-[500px] max-xl:w-full text-center gap-y-3">
        <p className="body20 font-semibold">{howItWorks.steps[0].title}</p>
        <p className="text-gray-400 ">{howItWorks.steps[0].description}</p>
      </div>
      <div className="flex max-xl:flex-col w-full justify-center items-center">
        <div className="border-[1px] border-dark-neutral-700 p-2 rounded-2xl max-xl:p-[6px]">
          <ConnectWalletDialog />
        </div>
        <div className="flex justify-center items-center max-xl:flex-col">
          <div className="w-10 border-[1.5px] border-gray-600 border-dashed max-xl:w-[1px] max-xl:h-8" />
          <p className="body16 font-semibold px-3 py-2 bg-status-success-400 bg-semantic-success-600 rounded-[12px]">
            Connecting
          </p>
          <div className="w-10 border-[1.5px] border-gray-600 border-dashed max-xl:w-[1px] max-xl:h-8" />
        </div>
        <div className="border-[1px] border-dark-neutral-700 p-2 rounded-2xl">
          <DRepProfile />
        </div>
      </div>
    </div>
  );
};
