import {
  _internal_ComponentMenuItems,
  Menu,
  MenuButton,
  MenuItems,
} from "@headlessui/react";
import React, { useState } from "react";
import cn from "classnames";
import { capitalize } from "lodash";
import Chevron from "@src/components/icons/chevron";
import MenuIcon from "@src/components/icons/menu";

export interface IMenuItem {
  label: string;
  onClick: () => void;
  icon?: React.ReactElement;
  className?: string;
}

type ButtonVariant = "primary" | "neutral";

interface IDropdown {
  label?: string;
  isDropDownIconEnable?: boolean;
  prefixIcon?: React.ReactElement;
  menuItems: React.ReactElement[];
  className?: string;
  contentWidth?: number;
  disabled?: boolean;
  variant?: ButtonVariant;
  anchor?: "top start" | "top end" | "bottom start" | "bottom end";
}

const BUTTON_CLASS: Record<ButtonVariant, string> = {
  primary:
    "text-white p-[10px] gap-2 hover:shadow-Default-Shadow-1 p-[10px] bg-purple-gradient",
  neutral:
    "text-gray-900 px-3 py-2 bg-white dark:text-white dark:bg-dark-neutral-800 border dark:border-dark-neutral-700",
};

export default function Dropdown({
  label,
  menuItems,
  isDropDownIconEnable = true,
  prefixIcon,
  className,
  disabled = false,
  contentWidth = 168,
  anchor = "bottom end",
  variant = "primary",
}: IDropdown) {
  const [isRotate, setRotate] = useState(false);
  return (
    <Menu>
      <MenuButton
        onClick={() => setRotate(!isRotate)}
        className={cn(
          "flex items-center body14 rounded-xl",
          className,
          BUTTON_CLASS[variant],
          disabled && "opacity-40 cursor-not-allowed",
        )}
      >
        {prefixIcon && <div>{prefixIcon}</div>}

        {label ? (
          capitalize(label)
        ) : (
          <MenuIcon className="text-neutral-600 dark:text-white" />
        )}
        {isDropDownIconEnable && (
          <Chevron
            className={cn(
              "text-neutral-400  h-5 w-5 transform transition-transform duration-300 ease-in-out",
              isRotate ? "rotate-180 pr-[5px]" : "pl-[5px]",
            )}
          />
        )}
      </MenuButton>
      <MenuItems
        transition
        key="menuItems"
        anchor={anchor}
        style={{ width: `${contentWidth}px` }}
        className=" !z-40 mt-3 shadow-dropdown-content-shadow gap-[10px]  border bg-white dark:border-dark-neutral-700 dark:shadow-l-Shadow-3 dark:bg-dark-neutral-900 text-neutral-800 border-neutral-200 transition duration-100 ease-out rounded-xl"
      >
        {menuItems}
      </MenuItems>
    </Menu>
  );
}
